import { get, post, put, patch, destroy } from 'api';
import config from 'config';
import moment from 'moment';

import { Question, ScheduleSetting, Survey, SurveyListItem, SurveyLocation } from '../models';
import { SurveyResp  } from 'components/survey/models/question';
import { SurveyConstants } from 'common/utils/shared/shared-constants';

const messagingUrl = config.get('api.messaging');
const messaging2Url = config.get('api.messaging2');

interface QuestionResponseObject {
  id: number;
  title: string;
  surveyId: number;
  type: string;
  isMandatory: boolean;
  description: string;
  category: string;
}

interface SurveyResponseObject {
  id: number;
  title: string;
  type: string;
  description?: string;
  isActive: boolean;
  isSent: boolean;
  isAutomaticSurvey: boolean;
  questions: QuestionResponseObject[];
  locations: SurveyLocation[];
  scheduleSetting: ScheduleSetting;
  message?: string;
  dealerLocationId: number;
  isAllLocations: boolean;
  feedbackCount: number;
}

interface QuestionRequestObject {
  id?: number;
  title: string;
  type: string;
  surveyId?: number;
  isMandatory: boolean;
  description?: string;
}

interface SurveyRequestObject {
  id?: number;
  title: string;
  type: string;
  description?: string;
  dealerId?: number;
  message?: string;
  dealerLocationId: number;
  isAllLocations: boolean;
  isAutomaticSurvey: boolean;
  questions?: QuestionRequestObject[];
  locations?: SurveyLocation[];
  scheduleSetting: ScheduleSetting | null;
}

interface SurveyLocationCreateRequest {
  dealerLocationId: number;
  facebookReviewUrl: string;
  googleReviewUrl: string;
}

interface CreateSurveyRequestObject {
  title: string;
  type: string;
  description?: string;
  dealerId: number;
  dealerLocationId: number;
  isAllLocations: boolean;
  locations: SurveyLocationCreateRequest[];
  queryEquipment: boolean;
  queryParts: boolean;
  queryServices: boolean;
  startingHour: string;
  endingHour: string;
  delayForHours: number;
}

interface FeedbackResponse {
  id: number;
  customerId: number;
  customerName: string;
  customerNpsGroup: string;
  submissionTime: string;
  response: any[];
  dealerLocationId: number;
}

// interface SurveyResponse {
//   LocationIds?: number[];
//   ResponseType:string;
//   DateFrom:Date;
//   DateTo:Date;
// }


export interface SurveySummaryResponseObject {
  responseCount: number;
  npsAverage: number;
  passivesPercentage: number;
  promotersPercentage: number;
  detractorsPercentage: number;
  loyaltyScore: number;
  segmentIds: number[];
  feedback: FeedbackResponse[];
  totalSurveySent: number;
}

function mapToSurveyRequest(survey: Survey): SurveyRequestObject {
  let scheduleSetting = { ...survey?.scheduleSetting };

  
  if (!scheduleSetting) {
    survey.scheduleSetting = {
      id: 0,
      delayForHours: 0,
      startingHour: SurveyConstants.DefaultStartHour,
      endingHour: SurveyConstants.DefaultEndHour,
      queryEquipment: false,
      queryParts: false,
      queryServices: false,
      surveyId: survey.id,
      useDaylightTime:false
    };
  }

  let surveyRequest: SurveyRequestObject = {
    title: survey.title,
    description: survey.description,
    type: survey.type,
    message: survey.message,
    dealerId: survey.dealerId,
    questions: survey.questions,
    locations: survey.locations,
    dealerLocationId: survey.dealerLocationId,
    isAllLocations: survey.dealerLocationId === 0, //survey.isAllLocations
    isAutomaticSurvey: survey.isAutomaticSurvey,
    scheduleSetting: scheduleSetting,
  };
  if (survey.id !== null) {
    surveyRequest.id = survey.id;
  }
  surveyRequest.locations = survey.locations;
  return surveyRequest;
}

function mapToSurvey(surveyResponse: SurveyResponseObject, getRequest: boolean): Survey {
  let scheduleSetting = { ...surveyResponse.scheduleSetting };
  if (surveyResponse.scheduleSetting == null) {
    scheduleSetting = {
      id: 0,
      delayForHours: 0,
      startingHour: SurveyConstants.DefaultStartHour,
      endingHour: SurveyConstants.DefaultEndHour,
      queryEquipment: false,
      queryParts: false,
      queryServices: false,
      surveyId: surveyResponse.id,
      useDaylightTime:false
    };
    }


  const survey: Survey = {
    ...surveyResponse,
    questions: surveyResponse.questions.map((question) => ({
      id: question.id,
      surveyId: question.surveyId,
      title: question.title,
      type: question.type,
      description: question.description,
      isMandatory: question.isMandatory,
      category: question.category,
    })),
    feedbackCount: surveyResponse.feedbackCount,
    scheduleSetting: scheduleSetting,
  };
  return survey;
}

export interface SurveysStatistics {
  responseCount: string;
  promotersPercentage: string;
  detractorsPercentage: string;
  loyaltyScore: string;
}

interface PublishedSurveyResponse {
  survey: Survey;
  customerId: number;
  departmentId: number;
  dealerLocationId: number;
  isCompleted: boolean;
}

export const SurveyAPI = {
  getSurveysByDealerId: async (
    dealerId: number,
    status: string,
    pageSize: number,
    pageNumber: number,
    location?: number
  ): Promise<{ surveys: SurveyListItem[]; total: number }> => {
    const url = `${messagingUrl}/surveys?dealerId=${dealerId}&status=${status}&pageSize=${pageSize}&pageNumber=${pageNumber}&location=${location}`;
    const { data } = await get<{
      surveys: SurveyListItem[];
      total: number;
    }>(url);
    return data;
  },
  createSurvey: async (survey: CreateSurveyRequestObject): Promise<Survey> => {
    const url = `${messagingUrl}/surveys`;
    const { data } = await post<SurveyResponseObject>(url, survey);
    return mapToSurvey(data, false);
  },
  updateSurvey: async (survey: Survey): Promise<Survey | null> => {
    const url = `${messagingUrl}/surveys`;
    if (survey != null) {
      const mapped = mapToSurveyRequest(survey);
      const { data } = await put<SurveyResponseObject>(url, mapped);
      return mapToSurvey(data, true);
    }
    return null;
  },
  updateSurveyLocation: async (
    surveyId: number,
    isAllLocations: boolean,
    surveyLocations: SurveyLocationCreateRequest[],
  ): Promise<Survey | null> => {
    const url = `${messagingUrl}/surveyLocations`;
    const { data } = await put<SurveyResponseObject>(url, { surveyId, isAllLocations, surveyLocations });
    return mapToSurvey(data, true);
  },
  getSurvey: async (surveyId: number): Promise<Survey> => {
    const url = `${messagingUrl}/surveys/${surveyId}`;
    const { data } = await get<SurveyResponseObject>(url);
    return mapToSurvey(data, true);
  },
  deleteSurvey: async (surveyId: number): Promise<{ message: string }> => {
    const url = `${messagingUrl}/surveys/${surveyId}`;
    const { data } = await destroy(url);
    return data;
  },
  activateSurvey: async (surveyId: number): Promise<{ message: string }> => {
    const url = `${messagingUrl}/surveys/${surveyId}/activate`;
    const { data } = await patch(url);
    return data;
  },
  deactivateSurvey: async (surveyId: number): Promise<{ message: string }> => {
    const url = `${messagingUrl}/surveys/${surveyId}/deactivate`;
    const { data } = await patch(url);
    return data;
  },
  duplicateSurvey: async (surveyId: number): Promise<{ id: string }> => {
    const url = `${messagingUrl}/surveys/${surveyId}/duplicate`;
    const { data } = await post(url);
    return data;
  },
  addFeedback: async (surveyId: number, feedback: any) => {
    let url = `${messagingUrl}/surveys/${surveyId}/feedback`;
    url = url.replace("v2/", "");
    const { data } = await post(url, feedback);
    return data;
  },
  getFeedbackSummary: async (surveyId: number, dealerLocationId?: number): Promise<SurveySummaryResponseObject> => {
    const url = `${messagingUrl}/surveys/${surveyId}/feedback/summary?dealerLocationId=${dealerLocationId}`;
    const { data } = await get<SurveySummaryResponseObject>(url);
    return data;
  },
  getSurveySentHistory: async (surveyId: number) => {
    const url = `${messagingUrl}/surveys/${surveyId}/history`;
    const { data } = await get(url);
    return data;
  },
  getMonthlyNps: async (dealerId: number, dealerLocationId: number): Promise<any> => {
    const url = `${messagingUrl}/surveys/feedback/npsgraph?dealerId=${dealerId}&dealerLocationId=${dealerLocationId}`;
    const { data } = await get(url);
    return data;
  },
  getSurveyStatistics: async (dealerId: number, dealerLocationId: number): Promise<SurveysStatistics> => {
    try {
      const url = `${messagingUrl}/surveyStatistics?dealerId=${dealerId}&dealerLocationId=${dealerLocationId}`;
      const { data } = await get<SurveysStatistics>(url);
      if (data.detractorsPercentage === 'NaN') {
        data.detractorsPercentage = 'N/A';
      }
      if (data.promotersPercentage === 'NaN') {
        data.promotersPercentage = 'N/A';
      }
      if (data.loyaltyScore === 'NaN') {
        data.loyaltyScore = 'N/A';
      }
      return data;
    } catch (error) {
      throw new Error('Unable to fetch survey stats');
    }
  },
  sendSurvey: async (
    surveyId: number,
    segmentIds: number[],
    surveyUri: string,
    dealerId?: number,
    dmsDealerId?: number,
  ): Promise<any> => {
    const url = `${messaging2Url}/surveys/sendSurvey?dealerId=${dealerId || ''}&dmsDealerId=${dmsDealerId || ''}`;
    const sendSurveyRequest = {
      surveyId,
      segmentIds,
      surveyUri,
    };
    const { data } = await post(url, sendSurveyRequest);
    return data;
  },
  getPublishedSurvey: async (hashId: string): Promise<PublishedSurveyResponse> => {
    let url = `${messagingUrl}/publishedSurvey/${hashId}`;
    url = url.replace("v2", "");
    const { data } = await get(url);
    return data;
  },
  addQuestion: async (surveyId: number, questionRequest: QuestionRequestObject): Promise<Question> => {
    const url = `${messagingUrl}/survey/${surveyId}/question`;
    const { data } = await post(url, questionRequest);
    return data;
  },
  GetSurveyResponse: async (surveyResponse:SurveyResp): Promise<any> => {
    // const url = `${messagingUrl}/getsurveyresponse/${surveyId}`;
    const url = `${messagingUrl}/v2/surveys/survey-inbox`;
    const { data } = await post(url, surveyResponse);
    return data;
  },

  GetSurveyStatistics: async (json:string): Promise<any> => {
    // const url = `${messagingUrl}/getsurveyresponse/${surveyId}`;
    const url = `${messagingUrl}/GetSurveyStatisticsV2/${json}`;
    const { data } = await await get(url);
    return data;
  },

  GetDashboard: async (json:string): Promise<any> => {
    const url = `${messagingUrl}/GetDashboard/${json}`;
    const { data } = await await get(url);
    return data;
  },


  FeedbackCSVDownload: async (surveyResponse:SurveyResp): Promise<any> => {
    // const url = `${messagingUrl}/getsurveyresponse/${surveyId}`;
    const url = `${messagingUrl}/v2/surveys/FeedbackCSVDownload`;
    const { data } = await post(url, surveyResponse);
    return data;
  },

};

export default SurveyAPI;
