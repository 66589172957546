import React,{ReactElement,useEffect,useState} from 'react'
import { Button, Typography, TextField, Paper, MenuItem } from "@mui/material";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import TextLimitField from 'common/components/input-fields/TextLimitField';
import {
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import GoogleAutocompleteResp from 'common/models/google-api';
import CustomerAPI from 'components/customer/api/CustomerAPI';
interface GoogleAutocompleteProp {
  index?: number | null | undefined; 
  label: string; 
  name: string;
  isSubmitted?: boolean;
  initValue?:string;
  formik?:any;
  onChange?:any;
  setResponse: any;
}

function GoogleAutocomplete({index,label,name,formik, isSubmitted,initValue, onChange,setResponse}: GoogleAutocompleteProp): ReactElement{
  const [address, setAddress] = useState<string>('');
  const handleSelect= async (value:any, placeId:any)=>{
        setFormData(index, placeId);
        setAddress(value);
  }
  const setFormData = async (index: any,placeId:any) => {
    const [place] = await geocodeByPlaceId(placeId);
    const {lat,lng} = await getLatLng(place);
    const {formatted_address} = place;
    let { long_name: postalCode = '' } = place.address_components.find((c) => c.types.includes('postal_code')) || {};
    const { long_name: city1 = '' } = place.address_components.find((c) => c.types.includes('locality')) || {};
    const { long_name: city2 = '' } = place.address_components.find((c) => c.types.includes('administrative_area_level_2')) || {};
    const { short_name: country = '' } = place.address_components.find(c => c.types.includes('country')) || {};
   
    const stateComponent = place.address_components.find(c => c.types.includes('administrative_area_level_1'));
    const { short_name: state = '', short_name: stateShort = '' } = stateComponent || {};

    if (postalCode == '') {
      const data = await CustomerAPI.GetZipcode(lat, lng);
      const postalCodeFromLatLng = data.results[0].address_components.find(
        (component: any) => component.types.includes('postal_code')
      ) || '';

      postalCode = postalCodeFromLatLng.long_name || '';
    }

    const resp: GoogleAutocompleteResp = {
      address1: formatted_address.split(',')[0],
      address2: "",
      postalCode: postalCode,
      city: city1 || city2,
      country: country,
      stateShort: stateShort,

      state: state,
      index: index,
    };
    setResponse(resp);
  };

  const handleChangeForm = (e: any) => {
    onChange(e);
    setAddress(e);
  };
  const searchOptions = {
    types: ['address'],
    componentRestrictions: {country: ["us","ca"]}
  }
  useEffect(()=>{
    setAddress(initValue||'');
  },[initValue])

  return (
    <>
      <PlacesAutocomplete
        value={address===initValue?initValue: address?.split(",")[0]}
        onSelect={handleSelect}
        searchOptions={searchOptions}
        onChange={handleChangeForm}
        >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <TextLimitField
                characters={50}
                fullWidth
                helperTextAlignLeft
                required
                margin="dense"
                id={name}
                style={name == "address1" ? { paddingTop: '5px', paddingBottom: '5px' }:{}}
                name={name}
                label={label}
                error={!!formik?.errors?.streetAddress && formik?.touched?.streetAddress}
                helperText={formik?.touched?.streetAddress && formik?.errors?.streetAddress }
                
                {...getInputProps({
                  placeholder:'123 Maple St, Ste. 111',
                  autoFocus: true,
                  disabled:isSubmitted,
                  className: "location-search-input",
                  variant: "outlined",
              })}
              onBlur={formik?.handleBlur}
            />
            <Paper className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#3f50b5", cursor: "pointer", color:'#FFFFFF' }
                  : { backgroundColor: "#FFFFFF", cursor: "pointer" };
                return (
                  <MenuItem
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </MenuItem>
                );
              })}
            </Paper>
            </>
        )}
      </PlacesAutocomplete>
      
    </>
  );
}

export default GoogleAutocomplete