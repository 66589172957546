/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Survey } from 'components/survey/models';

import { AppThunk } from 'app';

import SurveyAPI from '../../api/survey-api';

export interface PublishedSurveyState {
  loading: boolean;
  loader: boolean;
  error: string | null;
  survey: Survey | null;
  customerId: number | null;
  departmentId: number | null;
  dealerLocationId: number | undefined;
  isCompleted: boolean;
  feedback: any;
  savingFeedback: boolean;
  errorFeedback: string | null;
  feedbackSubmitted: boolean;
  isSubmittable: boolean;
}
const initialState: PublishedSurveyState = {
  loading: false,
  loader: false,
  error: null,
  survey: null,
  customerId: null,
  departmentId: null,
  dealerLocationId: undefined,
  isCompleted: false,
  feedback: {},
  isSubmittable: false,
  savingFeedback: false,
  errorFeedback: null,
  feedbackSubmitted: false,
};
const publishedSurvey = createSlice({
  name: 'publishedSurvey',
  initialState,
  reducers: {
    getPublishedSurveyStarted(state): void {
      state.loading = true;
      state.error = null;
    },
    getPublishedSurveySuccess(
      state,
      action: PayloadAction<{
        survey: Survey;
        customerId: number;
        departmentId: number;
        dealerLocationId: number;
        isCompleted: boolean;
      }>,
    ): void {
      state.error = null;
      state.loading = false;
      if (action.payload.isCompleted === false) {
        state.survey = action.payload.survey;
        state.customerId = action.payload.customerId;
        state.departmentId = action.payload.departmentId;
        state.dealerLocationId = action.payload.dealerLocationId;
        state.isCompleted = action.payload.isCompleted;
        state.survey.questions.forEach((q, idx) => {
          state.feedback[idx] = {
            answer: undefined,
            questionId: q.id,
          };
        });
      } else {
        state.isCompleted = action.payload.isCompleted;
      }
    },
    getPublishedSurveyFailure(state, action: PayloadAction<string>): void {
      state.loading = false;
      state.error = action.payload;
    },
    savingFeedbackStart(state): void {
      state.savingFeedback = true;
      state.errorFeedback = null;
    },
    savingFeedbackError(state, action: PayloadAction<string>): void {
      state.savingFeedback = false;
      state.errorFeedback = action.payload;
    },
    savingFeedbackSuccess(state): void {
      state.feedbackSubmitted = true;
      state.savingFeedback = false;
      state.errorFeedback = null;
    },
    setLoader(state, action: PayloadAction<boolean>): void {
      state.loader = action.payload;
    }
  },
});

export const {
  setLoader,
  getPublishedSurveyStarted,
  getPublishedSurveyFailure,
  getPublishedSurveySuccess,
  savingFeedbackStart,
  savingFeedbackSuccess,
  savingFeedbackError,
} = publishedSurvey.actions;

export default publishedSurvey.reducer;

export const fetchPublishedSurvey =
  (hashId: string): AppThunk =>
    async (dispatch): Promise<void> => {
      try {
        dispatch(getPublishedSurveyStarted());
        const response = await SurveyAPI.getPublishedSurvey(hashId);
        dispatch(
          getPublishedSurveySuccess({
            survey: response.survey,
            customerId: response.customerId,
            departmentId: response.departmentId,
            dealerLocationId: response.dealerLocationId,
            isCompleted: response.isCompleted,
          }),
        );
      } catch (error) {
        dispatch(getPublishedSurveyFailure(error.message));
      }
    };

export const submitSurveyFeedback =
  (surveyId: number, responses: any): AppThunk =>
    async (dispatch, getState): Promise<void> => {
      if (surveyId) {
          try {

          dispatch(setLoader(true));
          const { customerId, dealerLocationId, departmentId } = getState().publishedSurvey;
          dispatch(savingFeedbackStart());
          const requestObject = {
            surveyId,
            customerId,
            responses,
            dealerLocationId,
            departmentId
          };
          await SurveyAPI.addFeedback(surveyId, requestObject);
          dispatch(savingFeedbackSuccess());
          dispatch(setLoader(false));
        } catch (error) {
          dispatch(savingFeedbackError(error.message));
          dispatch(setLoader(false));
        }
      }
    };
