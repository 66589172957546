import { CustomerCategory } from 'components/customer/api/CustomerAPI';

export interface CustomerPhone {
  id: number;
  customer?: null;
  customerId: number;
  dealerId: number;
  lastUpdated: string;
  phoneNumber: string;
  extension: string;
  phoneType: number;
  contactId: number;
  isPrimary: boolean;
  isDefault:boolean;
  verificationStatus: number | null;
}
export interface CustomerEmail {
  contactId: number;
  address: string;
  customer?: null;
  customerId: number;
  dealerId: number;
  emailType: number;
  lastUpdated: string;
  isPrimary: boolean;
  isDefault:boolean;
}

export interface CustomerAddress {
  address1: string;
  address2: string;
  city: string;
  stateProvince: string;
  zipPostal: string;
  addressType: string;
  stateShort: string;
}

export interface CustomerDto {
  id: number;
  dealerId: number;
  name: string;
  cellPhone: string;
  verificationStatus: boolean;
  optIn: number;
  city: string;
  stateProvince: string;
  zipPostal: string;
  createdAt?: string;
}

export interface CustomerContactDto {
  crmCustomerId: number;
  customerId: number;
  customerName: string;
  contactId: number;
  contactName: string;
  phoneId: number;
  phoneNumber: string;
  isOrganization: boolean;
  organizationName: string;
  isAspenPrimaryContact: boolean;
}

export interface CustomerTableDto {
  id: number;
  dealerId: number;
  name: string;
  cellPhone: string;
  verificationStatus: boolean;
  optIn: number;
  city: string;
  stateProvince: string;
  zipPostal: string;
  isOrganization?:boolean;
  organizationName?:string;
  emailAddress?:string;
  address?:string;
  originationSource?: string;
  uniqueIdentifier?: string;
  createdAt?: any;
}

export interface CustomerTags {
  crmCustomerId:number;
  tag:string;
  lastUpdated:string;
}

interface Customer {
  crmCustomerId: number;
  dealerId: number;
  salesRepName: string;
  organizationName: string;
  customerAddresses: CustomerAddress[];
  customerPhones: CustomerPhone[];
  customerEmails: CustomerEmail[];
  customerCategories: CustomerCategory[];
  customerTags:CustomerTags[];
  conversationsPhoneIds: number[];
  optIn: number;
  optInEmail: boolean;
  isOrganization: boolean;
  name: string;
  firstName: string;
  middleName : string;
  lastName : string;
  customerNumber : number | null;
  jobTitle : string;
  dateOfBirth : Date | null;
  optOutDate: string;
  lastSurveyRating?: number | null;
  lastSurveyRatingDate?: Date | null;
  notes: string;
  primaryCellPhoneId?: number;
  primaryContactId?: number;
  primaryEmailId?:number;
  dob?:string;
  defaultPhoneNumberForMessenger:string;
  defaultPhoneType:number;
  salesStageId?: string | number | null;
  reviewFlag: boolean;
}

export interface CustomerNameObject {
  customerNumber: any;
  contactId: number;
  dateOfBirth: Date | null;
  firstName: string;

  jobTitle: string;
  lastName: string;
  middleName: string;
  name: string; // "JIM BROSCHE";
}

export enum PhoneVerificationStatusEnum {
  Verified = 1,
  Failed = 2,
}

export enum addressTypes {
  Billing = 0,
  Shipping = 1,
}
export interface CustomerTags {
  tag:string;
}

export default Customer;
