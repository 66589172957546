import SummarizeIcon from '@mui/icons-material/Summarize';
import { Box, Button, FormControl, CircularProgress, Divider, Grid, InputLabel,List, MenuItem, ListItemIcon ,Popover, Select, TextField, Theme, Typography, ListItemText, ListSubheader } from '@material-ui/core';
import MailIcon from '@mui/icons-material/Mail';
import HistoryIcon from '@mui/icons-material/History';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { createStyles, makeStyles } from '@material-ui/styles';
import { LoadingButton } from 'common/components/buttons/LoadingButton';
import Customer, { CustomerNameObject, CustomerPhone, CustomerTags } from 'common/models/customer';
import { useFormik } from 'formik';
import React, { forwardRef, ReactElement, Ref, useEffect, useImperativeHandle, useState } from 'react';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import { useAppDispatch, useAppSelector } from 'app';
import CustomerInvoiceDrawer from 'components/customer-groups/components/customer-group/customers-table/customer-invoice/';
import MessengerDrawer from 'components/messaging/components/messenger-drawer';
import CustomerContacts from '../customerContact';
import CustomerSummary from '../customerSummary';
import CustomerAPI, {
  CustomerCategory,
  CustomerEmailType,
  CustomerPhoneType,
} from 'components/customer/api/CustomerAPI';
import moment from 'moment';
import StyleIcon from '@mui/icons-material/Style';
import DealerAPI from 'components/authentication/api/dealer-api';
import AppSnackBar from 'components/snackbar/snackbar';
import { TextMaskCustomProps } from './TextMaskCustomProps';
import { CustomerProps } from './CustomerProps';
import { CustomerNameComponent } from './CustomerNameComponent';
import { CustomerFormRef } from './CustomerFormRef';
import { CompanyName } from './CompanyName';
import { CustomerAddressList } from './CustomerAddressList';
import { SalesPerson } from './SalesPerson';
import { SurveyRating } from './SurveyRating';
import { Birthday } from './Birthday';
import { CustomerCategoryComponent } from './CustomerCategoryComponent';
import { JobTitle } from './JobTitle';
import { CustomerPhoneList } from './CustomerPhoneList';
import { CustomerEmailList } from './CustomerEmailList';
import { Notes } from './Notes';
import { DmsTypeEnum } from 'common/enums/DmsTypeEnum';
import {fetchSelectedCustomer} from '../Customers/customersSlice';
import AddNewDeal from 'components/deals/drawer/addNewDealDrawer';
import { showTaskDrawer } from 'components/lead/components/task-board/taskboardSlice';
import CustomerSettingsApi from 'components/setting/api/customer-settings-api';
import { CustomerTagOptions, CustomerTagTitle } from 'components/setting/models/customer-tag-title';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import CustomerTagPopover from '../Customers/CustomerTagPopover';
import { salesStages } from 'common/utils/salesStages';

export const addressTypes = [
  {
    value: '1',
    label: 'Shipping',
  },
  {
    value: '2',
    label: 'Billing',
  },
];
let spacing = 265;
export const TextMaskCustom = (props: TextMaskCustomProps) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide
      keepCharPositions
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

function formatPhoneNumber(phoneNumberString: string) {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  else return cleaned;
}

const sanitizeTelePhoneInput = (value: string): string => value.replace(/\D+/g, '');

export const useStyles = makeStyles((theme: Theme): any =>
  createStyles({
    myTab: {
      border: '1px solid white',
      padding: '0px 5px',
      cursor: 'pointer',
      '&:hover': {
        border: '1px solid silver',
        color: 'green',
        // fontWeight: 'bold',
      },
    },
    root: {
      marginTop: 10,
      width: '80%',
      textAlign: 'center',
      backgroundColor: theme.palette.background.paper,
    },
    avatar: {
      backgroundColor: '#3f51b5',
    },
    successIcon: {
      color: theme.palette.success.main,
      fontSize: 12,
      paddingRight: 5,
      position: 'absolute',
      marginTop: 10,
      marginLeft: -15,
    },
    errorIcon: {
      color: theme.palette.error.main,
      fontSize: 12,
      paddingRight: 5,
      position: 'absolute',
      marginTop: 10,
      marginLeft: -15,
    },
    removeEmailButton: {
      marginTop: '10px',
      marginLeft: '10px',
    },
    smallChip: {
      height: '20px !important',
    },

    iconButton: {
      background: '#1976D2',
      color: 'white',
      // marginTop: '3px',
      marginLeft: '5px',
      // padding: '2px',
      height: '20px',
      width: '20px',
      fontSize: '1rem',
      '&:hover': {
        backgroundColor: '#1976D2 !important',
        color: 'white !important',
      },
    },
    errorMsg: {
      color: '#e53935',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '-0.06px',
      paddingLeft: '10px',
      paddingTop: '3px',
    },
    flagIcon: {
      cursor: 'pointer',
      marginRight: '10px',
      marginTop: '2px',
    },
  }),
);

const CustomerForm = forwardRef(
  (
    { customer, onFormSubmit, onClose, disabled, btnDisabled, validate }: CustomerProps,
    ref: Ref<CustomerFormRef>,
  ): ReactElement => {
    useImperativeHandle(ref, () => ({
      CompareChanges: function (callback: any) {
        let startValue = initialValues;
        startValue.customerCategories = [];
        let endValue = formik.values;
        endValue.customerCategories = [];

        if(endValue.customerNumber === 0)
          startValue.customerNumber = 0;

        if(startValue.name === null)
          startValue.name = ''; 
        if(startValue.firstName === null)
          startValue.firstName = '';
        if(startValue.middleName === null)
          startValue.middleName = '';       
        if(startValue.lastName === null)
          startValue.lastName = '';
        
        if(endValue.name === null)
          endValue.name = '';       
        if(endValue.firstName === null)
          endValue.firstName = '';      
        if(endValue.middleName === null)
          endValue.middleName = '';       
        if(endValue.lastName === null)
          endValue.lastName = '';

        let initial = JSON.stringify(startValue);
        let current = JSON.stringify(endValue);
        if (initial !== current) {
          callback(true);
        } else {
          callback(false);
        }
        return;
      },
      SubmitForm: function () {
        setIsFormSubmitted(true);
        formik.submitForm();
      },
    }));
    const classes: any = useStyles();
    const dispatch = useAppDispatch();
    const [showSnackBar] = AppSnackBar.useSnackBar();

    const [phoneTypes, setPhoneTypes] = useState<CustomerPhoneType[]>([]);
    const [emailTypes, setEmailTypes] = useState<CustomerEmailType[]>([]);
    const [categories, setCategories] = useState<CustomerCategory[]>([]);
    const [defaultCatLoader, setDefaultCatLoader] = useState(false);
    const [phoneEmailLoader, setPhoneEmailLoader] = useState(false);
    const [openInvoiceDrawer, SetOpenInvoiceDrawer] = React.useState<boolean>(false);
    const [openMessengerDrawer, SetOpenMessengerDrawer] = React.useState<boolean>(false);
    const [openContactDrawer, SetOpenContactDrawer] = React.useState<boolean>(false);
    const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
    const [openSummaryDrawer, SetOpenSummaryDrawer] = React.useState<boolean>(false);
    const [summaryCount, SetSummaryCount] = React.useState<number>(0);
    const [isCustomerNumberUnique, SetisCustomerNumberUnique] = React.useState<boolean>(true);
    const [popoverChipState, setPopoverChipState] = useState<any>({ openedPopoverId: null, anchorEl: null });
    const [customerTags, setCustomerTags] = useState<CustomerTagTitle[]>([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [openAddDealDrawer, setOpenAddDealDrawer] = useState<boolean>(false);
    const [tagsLoading, setTagsLoading] = useState<boolean>(true);
    const [searchInput, setSearchInput] = useState('');
    const [isReviewHubEnabled, setIsReviewHubEnabled] = useState(false);

    let dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);
    const dealerId = useAppSelector((state) => state.user.dealerId);
    let userPermissions = useAppSelector((state) => state.user?.userPermissions);
    let isInvoicePermission = 0;
    let isMessengerPermission = 0;
    let isTaskPermission = 0;

    const schema = Yup.object<any>({
      customerNumber: dmsTypeId == DmsTypeEnum.Aspen ? Yup.string().max(15, 'Max 15 characters allowed.').required('Required').nullable() : undefined,
    });
    let editMode = false;
    let initialValues: Customer = {
      crmCustomerId: 0,
      dealerId: 0,
      organizationName: '',
      salesRepName: '',
      firstName: '',
      lastName: '',
      middleName: '',
      isOrganization: false,
      name: '',
      dob: '',
      customerAddresses: [],
      customerCategories: [],
      customerEmails: [],
      customerPhones: [],
      customerTags: [],
      conversationsPhoneIds: [],
      optIn: 1,
      optInEmail: false,
      optOutDate: '',
      notes: '',
      defaultPhoneType: -1,
      customerNumber: null,
      dateOfBirth: null,
      jobTitle: '',
      salesStageId: customer && customer.salesStageId? customer.salesStageId : 0,
      defaultPhoneNumberForMessenger: '',
      reviewFlag: false,
    };
    if (customer !== undefined) {
      customer = {...customer};
      if (dmsTypeId === DmsTypeEnum.Infinity) {
        customer.customerPhones = customer.customerPhones.filter((x) => x.contactId === 0 || x.contactId === null);
        customer.customerEmails = customer.customerEmails.filter((x: any) => x.contactId === 0 || x.contactId === null);
      }
      if (dmsTypeId === DmsTypeEnum.Ideal) {
        customer.customerEmails = customer.customerEmails.filter(
          (x: any) => customer && x.contactId === 0);
        customer.customerPhones = customer.customerPhones.filter(
          (x: any) => customer && x.contactId === 0);
      }
      
      if (!userPermissions) {
        userPermissions = [];
        isInvoicePermission = 2;
        isMessengerPermission = 2;
        isTaskPermission = 2;
      }
      if (isInvoicePermission == 0) {
         if (userPermissions.find((x) => x.featureName.toLowerCase() === 'invoice')) {
          isInvoicePermission = 1;
        } 
      }

      if (isMessengerPermission == 0) {
        if (userPermissions.find((x) => x.featureName.toLowerCase() === 'messages')) {
          isMessengerPermission = 1;
       } 
     }

     if (isTaskPermission == 0) {
      if (userPermissions.find((x) => x.featureName.toLowerCase() === 'tasks')) {
        isTaskPermission = 1;
     } 
   }
     
      //Aspen
      if (dmsTypeId === DmsTypeEnum.Aspen) {
        customer.customerEmails = customer.customerEmails.filter(
          (x: any) => customer && (x.contactId === customer.primaryContactId || x.contactId === null),
        );
        customer.customerPhones = customer.customerPhones.filter(
          (x: any) => customer && (x.contactId === customer.primaryContactId || x.contactId === null),
        );
      }      
      initialValues = { ...customer };
      editMode = true;
    }

    const [formikValues, setFormikValues] = useState<Customer>(initialValues);

    const formik = useFormik({
      initialValues,
      validationSchema: schema,
      onSubmit: (values: Customer) => {
        let phoneNumbers: CustomerPhone[] = [];
        if (values.customerPhones) {
          values.customerPhones.forEach((x) => {
            if (x.phoneNumber) {
              let p = {
                ...x,
                phoneNumber: x.phoneNumber.replace(/[^0-9]/g,''),
              };
              if (dmsTypeId !== DmsTypeEnum.Aspen ) {
                p.isPrimary = true;
              }
              phoneNumbers.push(p);
            }
          });
        }

        //For Aspen at least one phone/email should be default
        if(dmsTypeId == DmsTypeEnum.Aspen ){
          if(values.customerPhones.length > 0 && !values.customerPhones.find(x=>x.isDefault == true)){
            showSnackBar({ type: 'error', message: 'At least one phone should be Default.' });
            return;
          }
          if(values.customerEmails.length > 0 && !values.customerEmails.find(x=>x.isDefault == true)){
            showSnackBar({ type: 'error', message: 'At least one email should be Default.' });
            return;
          }
        }
        if (!isCustomerNumberUnique) {
          return;
        }
        if (dmsTypeId === DmsTypeEnum.Aspen  && values.notes != null){
          const notesAspen = values.notes.replace(/\n/g, ' ').replace(/  +/g, ' ');
          values.notes = notesAspen;
        }
        // code for search filter
         if(dmsTypeId === DmsTypeEnum.Aspen)
           {
            values.name = values.firstName + " "+values.lastName;
           }
         else if(dmsTypeId === DmsTypeEnum.Ideal)
         {
             values.name = values.lastName + ", "+values.firstName;
          }
        const sanitizedValues = { ...values, customerPhones: phoneNumbers };
        onFormSubmit(sanitizedValues);
        formik.resetForm();
      },
    });
    
    useEffect(() => {
      async function fetchCategories() {
        try{

          setDefaultCatLoader(true);
          const dealerSettingsResponse = await DealerAPI.getDealerSettings(dmsDealerIdCrm);
          setIsReviewHubEnabled(dealerSettingsResponse?.isReviewHubEnabled);
          const defaultCategoryId = dealerSettingsResponse.dealerLocationSettings.length > 0 && dealerSettingsResponse.dealerLocationSettings[0].defaultCustomerCategoryId;
          const categories = await CustomerAPI.getCategories(dealerId);        
          setCategories(categories);
        if((defaultCategoryId != null) && (defaultCategoryId.toString() != "" )){
          const cat=categories.find((x) => x.customerCategoryId.toString() === defaultCategoryId.toString());
          formik.values.customerCategories.length <= 0 && formik.setFieldValue('customerCategories',[cat])
        }
        else{
          formik.values.customerCategories.length <= 0 && formik.setFieldValue('customerCategories',[categories[0]]);
        }
          setDefaultCatLoader(false);
        } catch{
          setDefaultCatLoader(false);
        }
      }
      fetchCategories();
    }, []);

    useEffect(() => {
      async function fetchLookups() {
        try{
          setPhoneEmailLoader(true)
          setTagsLoading(true);
          const staticTags = await CustomerSettingsApi.getCustomerTags();
          setTagsLoading(false);
          setCustomerTags(staticTags);
          const phoneTypes = await CustomerAPI.getPhoneTypes(dealerId);
          const emailTypes = await CustomerAPI.getEmailTypes(dealerId);
          setEmailTypes(emailTypes);
          setPhoneTypes(phoneTypes);
          setPhoneEmailLoader(false)
        } catch{
          setPhoneEmailLoader(false)
        }
      }
      fetchLookups();
    }, []);

    const tagExists = (selectedTagOption: CustomerTagOptions) => {
      const found = formik.values.customerTags.some((customerTag: any) =>
          selectedTagOption.tag === customerTag.tag
      );
      return found;
    };
     async function reloadCustomerData(crmCustomerId: number){
      dispatch(fetchSelectedCustomer(crmCustomerId));
    }

    function handleChipDelete(index: number) {
      let tags = [...formik.values.customerTags];
      tags.splice(index, 1);
      formik.setFieldValue('customerTags', tags);
    }
    const handlePopoverChipOpen = (event: any, popoverId: any) => {
      setPopoverChipState({
        openedPopoverId: popoverId,
        anchorEl: event.target,
      });
    };
    const handlePopoverChipClose = () => {
      setPopoverChipState({
        openedPopoverId: null,
        anchorEl: null,
      });
      const data = formik.values.customerTags.length - 1;
      if (formik.values.customerTags[data].tag === '') {
        let tags = [...formik.values.customerTags];
        tags.splice(data, 1);
        formik.setFieldValue('customerTags', tags);
      }
    };
    const addNewTAg = (event: any) => {
      let tags: any = [];

      if (formik.values.customerTags) {
        tags = [...formik.values.customerTags];
      }
      const customerTags: CustomerTags = {
        tag: '',
        crmCustomerId: customer?.crmCustomerId!,
        lastUpdated: moment.utc().format(),
      };
      tags.push(customerTags);
      tags.sort((x: CustomerTags, y: CustomerTags) => parseInt(x.tag) - parseInt(y.tag));
      formik.setFieldValue('customerTags', tags);
      const _index = tags.indexOf(customerTags);
      handlePopoverChipOpen(event, _index);
    };

    let customerNameObject: CustomerNameObject | undefined = undefined;
    if (customer) {
      customerNameObject = {
        customerNumber: formik.values?.customerNumber || 0,
        firstName: formik.values?.firstName || '',
        lastName: formik.values?.lastName || '',
        middleName: formik.values?.middleName || '',
        name: formik.values?.name || '',
        jobTitle: formik.values?.jobTitle,
        dateOfBirth: formik.values?.dateOfBirth,
        contactId: -1,
      };
    }
    function SetNameValues(customerName: any){
      formik.setFieldValue('name',customerName.name );
      formik.setFieldValue('firstName', customerName.firstName);
      formik.setFieldValue('middleName', customerName.middleName);
      formik.setFieldValue('lastName', customerName.lastName);
      formik.setFieldValue('customerNumber', customerName.customerNumber);
    }
    const onNameSubmit = (customerName: CustomerNameObject) => {
      formik.setFieldValue('name',  customerName.name);
      formik.setFieldValue('firstName', customerName.firstName);
      formik.setFieldValue('middleName', customerName.middleName);
      formik.setFieldValue('lastName', customerName.lastName);
      formik.setFieldValue('customerNumber', customerName.customerNumber);
    };
    const onCompanySubmit = (companyName: string) => formik.setFieldValue('organizationName', companyName);

    const onAddTaskBtn = () => dispatch(showTaskDrawer({ drawerType: 'TASK_ADD', drawerProps: {customer} }));
    
    const handleSearchChange = (event: any) => {
      setSearchInput(event.target.value);
    };
  
    const filteredTags = (title: any) => {
      if (!searchInput) return title.customerTagOptions;
      return title.customerTagOptions.filter((option: any) =>
        option.tag.toLowerCase().includes(searchInput.toLowerCase())
      );
    };

    const ReviewFlag = ({ reviewFlag, onClick }: { reviewFlag: boolean; onClick: any }) => {
      return reviewFlag ? (
        <FlagIcon className={classes.flagIcon} color="error" fontSize="large" onClick={onClick} />
      ) : (
        <FlagOutlinedIcon className={classes.flagIcon} fontSize="large" onClick={onClick} />
      );
    };

    return (
      phoneTypes &&
      emailTypes &&
      categories && (
        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <div style={{ display: 'flex', marginTop: '15px' }}>
          {(dmsTypeId != DmsTypeEnum.Ideal || !editMode || !customer?.isOrganization) && (
              <>
                <CustomerNameComponent
                  SetisCustomerNumberUnique={SetisCustomerNumberUnique}
                  customerNameObject={customerNameObject}
                  crmCustomerId={formik.values.crmCustomerId}
                  formikErrors={formik.errors}
                  onFormSubmit={(e) => {
                    onNameSubmit(e);
                  }}
                  dmsTypeId={dmsTypeId}
                  SetNameObject={SetNameValues}></CustomerNameComponent>
                  {isFormSubmitted && !!formik.errors?.customerNumber && <span style={{ marginLeft: '19px', fontWeight: 'bold', fontSize: 15}} className={classes.errorMsg}>Customer ID Required</span>} 
                  {dmsTypeId == DmsTypeEnum.Aspen && !isCustomerNumberUnique && formik.values.customerNumber && <span style={{ marginLeft: '19px', fontWeight: 'bold', fontSize: 15}} className={classes.errorMsg}></span>} 
              </> 
          )}
            <div style={{ flex: 1 }}></div>
            {isReviewHubEnabled && (
              <ReviewFlag
                reviewFlag={formik.values.reviewFlag}
                onClick={() => {
                  formik.setFieldValue('reviewFlag', !formik.values.reviewFlag);
                }}
              />
            )}

              <TextField
                    className={classes.textFieldPadding}
                    select
                    name="salesStageId"
                    style={{width: '220px'}}
                    // value={dealContext.defaultPipelineId && formik.values.pipelineId == 0 ? dealContext.defaultPipelineId : formik.values.pipelineId}
                    value={formik.values.salesStageId? formik.values.salesStageId : null}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          className: classes.muiPopoverPaper,
                        },
                      },
                    }}
                    InputLabelProps={{
                      shrink: formik.values.salesStageId !== null && formik.values.salesStageId !== 0,
                    }}
                    onChange={(event: any) => {
                      formik.setFieldValue('salesStageId', event.target.value)

                    }}
                    label="Sales Stage"
                    variant="outlined"
                    size="small">
                    {(salesStages?.map((stage, index) => {
                      return (
                        <MenuItem key={stage.id} value={stage.id}>
                          {stage.salesStage}
                        </MenuItem>
                      );
                    }))}
                  </TextField>
            </div>
            <hr style={{marginTop: '15px'}} />

          <Box mt={1}>
            <Grid container spacing={2}>
              {((dmsTypeId == DmsTypeEnum.Aspen && customer?.isOrganization) || customer?.isOrganization || !editMode) && dmsTypeId != DmsTypeEnum.Infinity && (
                <Grid item xs={6}>
                    <CompanyName formik={formik} formikErrors={formik.errors} editMode={editMode}></CompanyName>
                </Grid>
              )}
              <Grid
                item
                xs={6}
                onClick={() => {
                  SetSummaryCount(summaryCount + 1);
                }}>
                <SalesPerson formik={formik}></SalesPerson>
              </Grid>

              {editMode && (
                <Grid item xs={6}>
                  <SurveyRating
                    surveyRating={customer?.lastSurveyRating}
                    surveyRatingDate={customer?.lastSurveyRatingDate}></SurveyRating>
                </Grid>
              )}
              <Grid item xs={6}>
                <Birthday formik={formik}></Birthday>
              </Grid>
              {((customer && editMode) || !editMode) && dmsTypeId !== 9 && (
                <Grid item xs={6}>
                  <JobTitle formik={formik}></JobTitle>
                </Grid>
              )}

              <Grid item xs={6}>
                <CustomerCategoryComponent
                  formik={formik}
                  categories={categories}
                  catLoader={defaultCatLoader}
                  dmsType={dmsTypeId}></CustomerCategoryComponent>
              </Grid>
            </Grid>
          </Box>

          <hr />
          <CustomerAddressList formik={formik} />
          <hr />
          <CustomerPhoneList
            formik={formik}
            phoneTypes={phoneTypes}
            dmsTypeId={dmsTypeId}
            customer={customer}
            convPhoneIds={customer?.conversationsPhoneIds}
            loading={phoneEmailLoader}
          />
          <hr />
          <CustomerEmailList formik={formik} emailTypes={emailTypes} dmsTypeId={dmsTypeId} loading={phoneEmailLoader} />
          <hr />
          <Notes formik={formik}></Notes>
          <hr />
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item>
              <Grid container>
                <Grid item>
                  <StyleIcon style={{ marginTop: '-2px', marginRight: '5px' }} />
                </Grid>
                <Grid item>
                  <Typography variant="h5">Tags</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography>
                <AddIcon
                  onClick={(e: any) => {
                    addNewTAg(e);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} style={{ marginTop: '.5rem' }}>
              {formik.values.customerTags.map((x: CustomerTags, i: number) => (
                <>
                  <Chip
                    size="small"
                    label={x.tag}                  
                    variant="filled"
                    onDelete={(e: any) => {
                      handleChipDelete(i);
                    }}
                    sx={{ margin: '2px' }}
                  />
                  <Popover
                    id={'pop_' + i}
                    open={popoverChipState.openedPopoverId === i}
                    anchorEl={popoverChipState.anchorEl}
                    onClose={handlePopoverChipClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}>
                    <Box p={2} style={{ width: '300px' }}>
                    {/* <Grid container justifyContent="space-between">
                        <Grid item xs={12}>
                          <Autocomplete
                            options={customerTags || []}
                            getOptionLabel={(options) => options.tag}
                            onChange={(e: any) => {
                              formik.setFieldValue(`customerTags[${i}].tag`, e.target.textContent);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                autoFocus
                                margin="dense"
                                style={{ paddingTop: '5px', paddingBottom: '5px' }}
                                label="Add Tag"
                                onChange={(e: any) => {
                                  formik.setFieldValue(`customerTags[${i}].tag`, e.target.value);
                                }}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    handlePopoverChipClose();
                                  }
                                }}
                                value={x.tag}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Box mt={1} ml={2}>
                            <Button
                              fullWidth
                              id="saveCustomerAddressBtn"
                              color="primary"
                              variant="contained"
                              size="medium"
                              onClick={() => {
                                handlePopoverChipClose();
                              }}>
                              Close
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>                      */}

        <Grid container justifyContent="space-between">
          <CustomerTagPopover 
          formik={formik}
          customerTags={customerTags}
          setCustomerTags={setCustomerTags}
          setPopoverChipState = {setPopoverChipState}
          chipIndex={i}>
          </CustomerTagPopover>
       </Grid>

                    </Box>
                  </Popover>
                </>
              ))}
            </Grid>
          </Grid>
          {customer && (
            <Grid container justifyContent="space-between" style={{paddingTop: '30px', paddingBottom: '30px', paddingRight: '7px', paddingLeft: '7px'}}>
              {
                userPermissions.find((x) => x.featureName.toLowerCase() === "tasks")?                 
                <Grid item>
                <td>
                <LoadingButton
                    fullWidth
                    id="saveCustomerFormBtn"
                    color="primary"
                    variant="contained"
                    size="medium"
                    onClick={async () => {
                      onAddTaskBtn();
                    }}
                  >
                    Assign Tasks
                  </LoadingButton>
                </td>
              </Grid>
                :
                <></>
              }              
              {
                userPermissions.find((x) => x.featureName.toLowerCase() === "deals")?                 
                <Grid item>
                <td>
                  <LoadingButton
                    fullWidth
                    id="saveCustomerFormBtn"
                    color="primary"
                    variant="contained"
                    size="medium"
                    onClick={async () => {
                      setOpenAddDealDrawer(true);
                    }}
                  >
                    Create Deal
                  </LoadingButton>
                </td>
              </Grid>
                :
                <></>
              }
              
            </Grid>
          )}

          {openContactDrawer && (
            <CustomerContacts
              customer={customer}
              open={openContactDrawer}
              defaultPhoneType={formik.values.defaultPhoneType}
              convPhoneIds={customer?.conversationsPhoneIds}
              onClose={() => {
                SetOpenContactDrawer(false);
                reloadCustomerData(customer?.crmCustomerId!);
              }}
            />
          )}
          {/* {summaryCount > 4 || openSummaryDrawer && ( */}
          {openSummaryDrawer && (
            // <h1>Test</h1>
            <CustomerSummary
              customer={customer}
              isInvoice = {isInvoicePermission}
              isMessenger = {isMessengerPermission}
              isTaskPermission = {isTaskPermission}
              open={openSummaryDrawer}
              // onClose={() => {
              //   SetSummaryCount(0);
              onClose={() => {
                SetOpenSummaryDrawer(false);
              }}></CustomerSummary>
          )}
            {
              openAddDealDrawer && 
                <AddNewDeal
                renderDeals={() => {}}
                open={openAddDealDrawer}
                onClose={() => setOpenAddDealDrawer(false)}
                customer={customer}
                ></AddNewDeal>
            }
          <Box style={{ position: 'absolute', top: '3px', right: '80px' }}>
            {/* <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center"> */}
            {/* <Grid item>
              <Button id="OrderMessengerFormBtn" variant="contained" onClick={(event) => { SetOpenMessengerDrawer(true) }}>
                Messenger
              </Button>
              <Button id="OrderHistoryCustomerFormBtn" variant="contained" onClick={(event) => { SetOpenInvoiceDrawer(true) }}>
                Order History
              </Button>

            </Grid> */}

            {/* <Grid item>
              <Button id="cancelCustomerFormBtn" variant="contained" onClick={onClose}>
                Cancel
              </Button>

            </Grid> */}
            {/* <Grid item> */}

            <table cellPadding={0} cellSpacing={0} style={{ border: '0px solid silver' }}>
              <tr>
                {/* ContactsIcon */}
                <td>
                  {typeof customer?.crmCustomerId !== 'undefined' &&
                    customer?.crmCustomerId != null &&
                    customer?.crmCustomerId > 0 && (
                      <Box style={{ border: '1px solid silver', padding: '1px 2px' }}>
                        <Box display="flex">
                          <Box sx={{ display: 'inline-flex' }}>
                            <Box
                              sx={{ display: 'inline-flex', border: '1px solid red', padding: '1px 1px' }}
                              className={classes.myTab}
                              onClick={() => {
                                SetOpenSummaryDrawer(true);
                              }}>
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                style={{ border: '0px solid red', padding: '0px 10px' }}>
                                <tr>
                                  <td style={{ border: '0px solid blue', textAlign: 'center' }}>
                                    <SummarizeIcon
                                      color="primary"
                                      className={classes.myTab}
                                      style={{ border: '0px solid red', padding: '0px 11px 8px 11px', margin: '0px' }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ border: '0px solid blue' }}>
                                    {/* <div style={{ fontSize: "small", border: "0px solid green"}}><div style={{position:"absolute", marginTop:"-17px"}}>Contacts</div></div> */}
                                    <div
                                      style={{
                                        fontSize: 'small',
                                        border: '0px solid green',
                                        position: 'absolute',
                                        marginTop: '-15px',
                                        marginLeft: '-5px',
                                      }}>
                                      Summary
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </Box>
                            <Box
                              sx={{ display: 'inline-flex', border: '1px solid silver', padding: '1px 2px' }}
                              className={classes.myTab}
                              onClick={() => {
                                SetOpenContactDrawer(true);
                              }}>
                              <table cellPadding={0} cellSpacing={0}>
                                <tr>
                                  <td style={{ border: '0px solid blue', textAlign: 'center' }}>
                                    <ContactMailIcon
                                      color="primary"
                                      className={classes.myTab}
                                      style={{ border: '0px solid red', padding: '0px 11px 8px 11px', margin: '0px' }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ border: '0px solid blue' }}>
                                    {/* <div style={{ fontSize: "small", border: "0px solid green"}}><div style={{position:"absolute", marginTop:"-17px"}}>Contacts</div></div> */}
                                    <div
                                      style={{
                                        fontSize: 'small',
                                        border: '0px solid green',
                                        position: 'absolute',
                                        marginTop: '-15px',
                                        marginLeft: '-5px',
                                      }}>
                                      Contacts
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </Box>
                            
                            <Box style={{ display: isInvoicePermission == 1 ? "block" : "none" }}
                              sx={{ display: 'inline-flex', padding: '1px 2px' }}
                              className={classes.myTab}
                              onClick={() => {
                                SetOpenInvoiceDrawer(true);
                              }}>
                              <table cellPadding={0} cellSpacing={0} style={{ border: '0px solid red' }}>
                                <tr>
                                  <td style={{ border: '0px solid blue', textAlign: 'center' }}>
                                    <HistoryIcon
                                      color="primary"
                                      className={classes.myTab}
                                      style={{ border: '0px solid red', padding: '0px 10px 8px 10px', margin: '0px' }}
                                    />
                                  </td>
                                </tr>
                                <tr> 
                                  <td style={{ border: '0px solid blue' }}>
                                    {/* <div style={{ fontSize: "small", border: "0px solid green"}}><div style={{position:"absolute", marginTop:"-17px"}}>Contacts</div></div> */}
                                    <div
                                      style={{
                                        fontSize: 'small',
                                        border: '0px solid green',
                                        position: 'absolute',
                                        marginTop: '-15px',
                                        marginLeft: '3px',
                                      }}>
                                      Orders
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </Box>

                            <Box style={{ display: isMessengerPermission == 1 ? "block" : "none" }}
                              sx={{ display: 'inline-flex', padding: '1px 2px' }}
                              className={classes.myTab}
                              onClick={() => {
                                SetOpenMessengerDrawer(true);
                              }}>
                              <table cellPadding={0} cellSpacing={0} style={{ border: '0px solid red' }}>
                                <tr>
                                  <td style={{ border: '0px solid blue', textAlign: 'center' }}>
                                    <MailIcon
                                      color="primary"
                                      className={classes.myTab}
                                      style={{ border: '0px solid red', padding: '0px 5px 8px 5px', margin: '0px' }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ border: '0px solid blue' }}>
                                    {/* <div style={{ fontSize: "small", border: "0px solid green"}}><div style={{position:"absolute", marginTop:"-17px"}}>Contacts</div></div> */}
                                    <div
                                      style={{
                                        fontSize: 'small',
                                        border: '0px solid green',
                                        position: 'absolute',
                                        marginTop: '-15px',
                                        marginLeft: '5px',
                                      }}>
                                      Text
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </Box>

                            {/* <Box sx={{ display: 'inline-flex', border: "1px solid silver", padding: "1px 2px" }} className={classes.myTab} onClick={() => { SetOpenInvoiceDrawer(true) }}>
                          <table cellPadding={0} cellSpacing={0}>
                            <tr>
                              <td style={{ border: "0px solid blue", textAlign: "center" }}>
                                <HistoryIcon color="primary" className={classes.myTab} style={{ border: "0px solid red", padding: "0px", margin: "0px" }} />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ border: "0px solid blue" }}>
                                <div style={{ fontSize: "small", border: "0px solid green"}}>Order</div>
                              </td>
                            </tr>
                          </table>
                        </Box>
                        <Box sx={{ display: 'inline-flex', border: "1px solid silver", padding: "1px 2px" }} className={classes.myTab} onClick={() => { SetOpenMessengerDrawer(true) }}>
                          <table cellPadding={0} cellSpacing={0}>
                            <tr>
                              <td style={{ border: "0px solid blue", textAlign: "center" }}>
                                <MailIcon color="primary" className={classes.myTab} style={{ border: "0px solid red", padding: "0px", margin: "0px" }} />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ border: "0px solid blue"}}>
                                <div style={{ fontSize: "small", border: "0px solid green"}}>Text</div>
                              </td>
                            </tr>
                          </table>
                        </Box> */}
                          </Box>
                        </Box>
                      </Box>
                      // </IconButton>
                    )}
                </td>

                {/* <td>
                {typeof (customer?.crmCustomerId) !== 'undefined' && customer?.crmCustomerId != null && customer?.crmCustomerId > 0 && (
                  // <IconButton color="primary" aria-label="upload picture" style={{ border: "0px solid red" }} onClick={() => { SetOpenInvoiceDrawer(true) }} >
                  <Box onClick={() => { SetOpenInvoiceDrawer(true) }}>
                    <Tooltip arrow title={"Order History"}>
                      <HistoryIcon color="primary" className={classes.myTab} />
                    </Tooltip>
                  </Box>
                  // </IconButton>

                )}
              </td> */}

                <td style={{ paddingLeft: '5px', paddingTop: '4px' }}>
                  <LoadingButton
                    fullWidth
                    id="saveCustomerFormBtn"
                    color="primary"
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      console.log(formik.errors);
                      setIsFormSubmitted(true);
                      formik.handleSubmit();
                    }}>
                    Save
                  </LoadingButton>
                </td>
              </tr>
            </table>
            {/* </Grid> */}
            {/* </Grid> */}
          </Box>
          <CustomerInvoiceDrawer
            open={openInvoiceDrawer}
            onClose={handleInvoiceDrawerClose}
            invoiceId={0}
            dealerId={customer?.dealerId}
            invoiceRow={[]}
            crmCustomerId={customer?.crmCustomerId}>
            {/* Don't Delete This Line*/}
          </CustomerInvoiceDrawer>

          {openMessengerDrawer && (
            <MessengerDrawer
              defaultSearch={'CrmCustomerId:' + customer?.crmCustomerId.toString()}
              phoneNumber={customer?.defaultPhoneNumberForMessenger}
              open={openMessengerDrawer}
              onClose={() => {
                SetOpenMessengerDrawer(false);
                reloadCustomerData(customer?.crmCustomerId!);
              }}></MessengerDrawer>
          )}
        </form>
      )
    );


    function handleInvoiceDrawerClose() {
      SetOpenInvoiceDrawer(false);
    }
  },
);
export default CustomerForm;


