export function executeScriptForTeamSupportWidget(apiKey) {
  let se = document.createElement('script');
  se.type = 'text/javascript';
  se.async = true;
  se.src = `https://storage.googleapis.com/code.snapengage.com/js/${apiKey}.js`;
  let done = false;
  se.onload = se.onreadystatechange = function () {
    if (!done && (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete')) {
      done = true;
      /* Place your SnapEngage JS API code below */
      /* SnapEngage.allowChatSound(true); Example JS API: Enable sounds for Visitors. */
    }
  };
  let s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(se, s);
}
