import config from 'config';
import { Box, Button, Chip, CircularProgress, IconButton, InputAdornment, Link, Popover, Select, TextField, Tooltip } from '@material-ui/core';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import { useRef, useState } from 'react';
import axios from 'axios';
import { showSnackbar } from 'components/root-snackbar';
import { useAppDispatch } from 'app';
import { store } from 'app';
import React from 'react';
import { GetAttachments } from 'dmsAppsCallbacks';
import { fetchCustomers } from '../Customers/customersSlice';
import AddIcon from '@mui/icons-material/Add';
import { CustomerTagTitle } from 'components/setting/models/customer-tag-title';
import CustomerTagPopover from '../Customers/CustomerTagPopover';
import Typography from '@mui/material/Typography';

function CustomerFileUpload() {
    const [importFile, setImportFile] = useState() as any;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showPopOver, SetSowPopOver] = useState(false) as any;
    const [tag, setTag] = useState('') as any;
    const [responseError, SetResponseError] = useState('') as any;
    const [sendingData, setSendingData] = useState(false) as any;
    const [customerTags, setCustomerTags] = useState<CustomerTagTitle[]>([]);
    const [popoverChipState, setPopoverChipState] = useState<any>({ openedPopoverId: null, anchorEl: null });
    const inputRef = useRef(null) as any;
    const dispatch = useAppDispatch();

    function isFileCsv(el: any) {
        let regex = new RegExp("(.*?)\.(csv)$");
         if(!regex.test(el.target.files[0].name.toLowerCase())){
            dispatch(showSnackbar({ type: 'error', message: 'File type must be .csv' }));
            return;
         }else{
             setImportFile(el.target.files[0]);
         }
    }
    
    function handleFileSelected(event: any) {
        isFileCsv(event);
    }

    function ShowPopOver(event: any) {
        setAnchorEl(event.currentTarget);
        SetSowPopOver(true);
    }

    

    function submitImport(event: any) {
        SetResponseError('');
        if (importFile == undefined || importFile == null) {
            dispatch(showSnackbar({ type: 'error', message: 'Please select a .csv file to upload' }));
            return;
        }
        setSendingData(true);
        let customerUrl = config.get('api.customer');
        let env = config.get('env');
        let url = `${customerUrl}/CustomerFileUpload/${store.getState().user.dealerId}/${store.getState().user.isUserAdmin}`;
        url=url.replace('v2/','');
        let formData = new FormData();
        formData.append("file", importFile);
        formData.append("tag", tag);
        axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                const respObj = res.data[0];
                //ReviewHub and Flagging - Reverted
                // const respMsg = `Customer created: ${respObj.CustomersCreated}, existed: ${respObj.CustomersUpdated}, moved To review hub: ${respObj.CustomersMovedToReviewhub}`;
                const respMsg = `Customer created: ${respObj.CustomersCreated}, existed: ${respObj.CustomersUpdated}`;
                dispatch(showSnackbar({ type: 'success', message: respMsg }));
                setSendingData(false);
                inputRef.current.value = null;
                dispatch(fetchCustomers());
                SetSowPopOver(false);
                setImportFile(null);
            })
            .catch((error) => {
                // alert(error);
                // // Error
                setSendingData(false);
                if (error.response) {
                    let errorMessage = error.response.data.toString();
                    //alert(errorMessage);
                    SetResponseError(errorMessage);                    
                    alert(errorMessage);
                    //alert(error.response.data);
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    //alert('k2');
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the 
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    alert(error.request);
                } else {
                    //alert('k3');
                    alert(error.message);
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                // console.log(error.config);
            });

    }

    const handlePopoverChipOpen = (event: any, popoverId: any) => {
        setPopoverChipState({
          openedPopoverId: popoverId,
          anchorEl: event.target,
        });
      };

    const handlePopoverChipClose = () => {
        setPopoverChipState({
          openedPopoverId: null,
          anchorEl: null,
        });
      };

    return (
        <span>
            <Tooltip title="Upload customer file">
                <IconButton id="addImportCustomerFromFile" onClick={ShowPopOver}>
                    <FileUploadIcon></FileUploadIcon>
                </IconButton>
            </Tooltip>
            <Popover
                open={showPopOver}
                anchorEl={anchorEl}
                onClose={() => SetSowPopOver(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >

                <Box style={{ backgroundColor: "#F2F3F4" }}>
                    <table style={{ width: "400px" }}>
                        <tr>
                            <td style={{ paddingLeft: "10px" }}>
                                Customer&nbsp;file&nbsp;upload
                            </td>
                            <td style={{ width: "100%", paddingLeft: "20px", fontWeight: "bold" }}>
                                <Link href="#" style={{ fontSize: "small" }} onClick={() => {
                                    window.location.href = "https://stdealer001.blob.core.windows.net/temp/TargetCustomerUploadSample.csv";
                                }}>(Sample&nbsp;CSV&nbsp;File)</Link>

                            </td>
                            <td>
                                <IconButton onClick={() => SetSowPopOver(false)}>
                                    <CloseIcon fontSize='small'></CloseIcon>
                                </IconButton>

                            </td>

                        </tr>
                    </table>

                </Box>
                <Box style={{ padding: "10px" }}>
                    <input ref={inputRef} accept='.csv' type="file" onClick={(event)=>{
                        // if (localStorage.getItem('Login_Source')?.toString() === 'DMS_IDEAL' || localStorage.getItem('Login_Source')?.toString() === 'DMS_INFINITY') {
                        //     event.preventDefault();
                        //     GetAttachments(26214400,'csv');                            
                        //   }                          
                    }} onChange={handleFileSelected} />


                </Box>
                <Box style={{ padding: "2px 10px" }}>
                    <table>
                        <tr>
                            <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        type="text"
                                        label="Tag"
                                        name="withInRadius"
                                        onClick={(e) => {handlePopoverChipOpen(e, 1)}}
                                        value={''} 
                                        InputProps={{
                                            readOnly: true, // Makes the field non-editable
                                            startAdornment: tag && (
                                            <InputAdornment position="start">
                                                <Chip
                                                label={
                                                    <div
                                                    style={{
                                                      whiteSpace: 'nowrap',         // Prevents line breaks
                                                      overflow: 'hidden',           // Hides overflowing text
                                                      textOverflow: 'ellipsis',     // Adds ellipsis (...)
                                                      maxWidth: '110px',            // Set a maximum width for truncation
                                                    }}
                                                  >
                                                    {tag}
                                                  </div>
                                                }
                                               
                                                style={{ backgroundColor: '#e0e0e0', color: '#424242', height: '24px' }} // Grey filled Chip
                                                onDelete={() => {setTag('')}}
                                                />
                                            </InputAdornment>
                                            ),
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={(e) => handlePopoverChipOpen(e, 1)}>
                                                <AddIcon />
                                                </IconButton>
                                            </InputAdornment>
                                            )
                                        }}
                                        style={{ width: '250px' }}
                                        />
                                    <Popover
                                        id={'pop_' + 1}
                                        open={popoverChipState.openedPopoverId}
                                        anchorEl={popoverChipState.anchorEl}
                                        onClose={handlePopoverChipClose}
                                        anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'right',
                                        }}>
                                        <Box p={2} style={{ width: '300px' }}>
                                            <CustomerTagPopover
                                                tag={tag}
                                                setTag={setTag}
                                                customerTags={customerTags}
                                                setCustomerTags={setCustomerTags}
                                                setPopoverChipState = {setPopoverChipState}
                                            >
                                        </CustomerTagPopover>
                                        </Box>
                                        </Popover>
                            </td>
                            <td>
                                <div style={{paddingLeft: '10px' }}>
                                <Button disabled={sendingData} onClick={submitImport} variant="contained">
                                    {!sendingData && <span>Upload</span>}
                                    {sendingData && <CircularProgress size={24} />}
                                </Button>
                                </div>
                            </td>
                        </tr>
                    </table>
                </Box>
                <Box>
                    {responseError != '' && (
                        <Box style={{ padding: "5px 10px", color: "red" }}>
                            <hr></hr>
                            Error: {responseError}
                        </Box>
                    )}
                </Box>
            </Popover>

        </span>
    )
}
export default CustomerFileUpload;
