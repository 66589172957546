import {
  Box,
  Button, Grid,
  IconButton, MenuItem,
  Popover, TextField, Typography
} from '@material-ui/core';
import { CustomerAddress } from 'common/models/customer';
import React, { ReactElement, useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import Chip from '@mui/material/Chip';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddIcon from '@mui/icons-material/Add';
import TextLimitField from 'common/components/input-fields/TextLimitField';
import { useStyles, addressTypes } from './CustomerForm';
import GoogleAutocomplete from 'common/components/input-fields/GoogleAutocomplete';
import GoogleAutocompleteResp from 'common/models/google-api';

export function CustomerAddressList({ formik }: { formik: any; }): ReactElement {
  const [popoverState, setPopoverState] = useState<any>({ openedPopoverId: null, anchorEl: null });
  const billingAdded = formik.values.customerAddresses?.filter((x: CustomerAddress) => x.addressType == '2')[0];
  const [googleApiData, setGoogleApiData] = useState<GoogleAutocompleteResp>();

  const [click, setClick] = useState<boolean>(false);
  const handlePopoverOpen = (event: any, popoverId: any) => {
    setPopoverState({
      openedPopoverId: popoverId,
      anchorEl: event.target,
    });
  };
  const handlePopoverClose = () => {
    setClick(false);
    setPopoverState({
      openedPopoverId: null,
      anchorEl: null,
    });
  };
  const addAddressRow = (event: any) => {
    let addresses: any = [];

    if (formik.values.customerAddresses) {
      addresses = [...formik.values.customerAddresses];
    }
    const customerAddress: CustomerAddress = {
      address1: '',
      address2: '',
      addressType: '1',
      city: '',
      stateProvince: '',
      zipPostal: '',
      stateShort: ''
    };
    addresses.push(customerAddress);
    addresses.sort((x: CustomerAddress, y: CustomerAddress) => parseInt(x.addressType) - parseInt(y.addressType));
    formik.setFieldValue('customerAddresses', addresses);
    const _index = addresses.indexOf(customerAddress);
    handlePopoverOpen(event, _index);
  };

  const removeAddressRow = (index: number) => {
    let addresses = [...formik.values.customerAddresses];
    addresses.splice(index, 1);
    formik.setFieldValue('customerAddresses', addresses);
  };
  const handleZipCodeChange = (e:any, index:number) => {
    let newValue = e.target.value;
    if (newValue.length > 5 && !newValue.includes('-')) {
      newValue = newValue.substring(0, 5) + '-' + newValue.substring(5);
    } else if (newValue.length < 7) {
      newValue = newValue.substring(0, 5);
    }

    formik.setFieldValue(`customerAddresses[${index}].zipPostal`, newValue);
  };

  const classes: any = useStyles();

  // Call the handleGoogleApiResponce function when needed
  useEffect(() => {
    if (googleApiData) {
      formik.setFieldValue(`customerAddresses[${googleApiData?.index}].address1`, googleApiData?.address1);
      formik.setFieldValue(`customerAddresses[${googleApiData?.index}].address2`, googleApiData?.address2);
      formik.setFieldValue(`customerAddresses[${googleApiData?.index}].zipPostal`, googleApiData.postalCode);
      formik.setFieldValue(`customerAddresses[${googleApiData?.index}].city`, googleApiData.city);
      formik.setFieldValue(`customerAddresses[${googleApiData?.index}].stateProvince`, googleApiData.state);
      formik.setFieldValue(`customerAddresses[${googleApiData?.index}].stateShort`, googleApiData.stateShort);

    }
  }, [googleApiData]);

  return (
    <>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item>
          <Grid container>
            <Grid item>
              <LocationOnIcon style={{ marginTop: '-2px', marginRight: '5px' }} />
            </Grid>
            <Grid item>
              <Typography variant="h5">Address</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography>
            <AddIcon
              onClick={(e: any) => {
                addAddressRow(e);
              }}
              style={{ cursor: 'pointer' }} />
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          {formik.values.customerAddresses &&
            formik.values.customerAddresses.map((row: any, index: number) => {
              return (
                <>
                  <Box mt={1}>
                    <Grid container spacing={0}>
                      <Grid item>
                        <Typography variant="body1">
                          {(formik.values.customerAddresses[index].address1
                            ? formik.values.customerAddresses[index].address1 + ''
                            : '') +
                            (formik.values.customerAddresses[index].address2
                              ? ' ' + formik.values.customerAddresses[index].address2 + ''
                              : '') +
                            (formik.values.customerAddresses[index].address1 && formik.values.customerAddresses[index].city? ', ' : '') +
                            (formik.values.customerAddresses[index].city ? formik.values.customerAddresses[index].city : '') +
                            (formik.values.customerAddresses[index].stateProvince ? ', ' + formik.values.customerAddresses[index].stateProvince : '') +
                            (formik.values.customerAddresses[index].zipPostal ? ', ' + formik.values.customerAddresses[index].zipPostal : '')
                            }
                          &nbsp;
                          <Chip
                            className={classes.smallChip}
                            label={formik.values.customerAddresses[index].addressType == '2' ? 'Billing' : 'Shipping'}
                            color="primary"
                            variant="filled" />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          style={{ marginTop: '-10px' }}
                          aria-describedby={index.toString()}
                          onClick={(e: any) => handlePopoverOpen(e, index)}
                          className={classes.iconButton}>
                          <EditIcon style={{ fontSize: '1rem' }} />
                        </IconButton>
                        {/* <EditIcon style={{ marginLeft: '10px', cursor: "pointer" }} aria-describedby={index.toString()} onClick={(e: any) => (handlePopoverOpen(e, index))} fontSize='small' /> */}
                        <ClearIcon
                          aria-describedby={index.toString()}
                          onClick={(e: any) => {
                            removeAddressRow(index);
                          }}
                          fontSize="small"
                          style={{ cursor: 'pointer' }} />
                      </Grid>
                    </Grid>
                  </Box>
                  <Popover
                    id={'pop_' + index}
                    open={popoverState.openedPopoverId === index}
                    anchorEl={popoverState.anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}>
                    <Box p={2} style={{ width: '500px' }}>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={12}>
                          <GoogleAutocomplete
                            index={index}
                            name={"address1"}
                            label={"Address"}
                            setResponse={setGoogleApiData}
                            onChange={(e: any) => {
                              formik.setFieldValue(`customerAddresses[${index}].address1`, e);
                              formik.setFieldValue(`customerAddresses[${index}].address2`, '');
                            }}
                            initValue={row.address1+row.address2}
                          />
                        </Grid>
                        {/* <Grid item xs={12}>
                          <TextLimitField
                            characters={50}
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            id="address2"
                            style={{ paddingTop: '5px', paddingBottom: '5px' }}
                            name="address2"
                            label="Address 2"
                            value={row.address2}
                            onChange={(e: any) => {
                              formik.setFieldValue(`customerAddresses[${index}].address2`, e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }} />
                        </Grid> */}
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <TextLimitField
                              characters={15}
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              id="City"
                              style={{ paddingTop: '5px', paddingBottom: '5px' }}
                              name="City"
                              label="City"
                              disabled = {true}
                              value={row.city}
                              onChange={(e: any) => {
                                formik.setFieldValue(`customerAddresses[${index}].city`, e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextLimitField
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              id="stateProvince"
                              style={{ paddingTop: '5px', paddingBottom: '5px' }}
                              name="stateProvince"
                              label="State/Province"
                              disabled = {true}
                              value={formik.values.customerAddresses[index].stateProvince}
                              onChange={(e: any) => {
                                formik.setFieldValue(`customerAddresses[${index}].stateProvince`, e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              characters={15}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextLimitField
                              characters={10}
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              disabled = {true}
                              id="zipPostal"
                              style={{ paddingTop: '5px', paddingBottom: '5px' }}
                              name="zipPostal"
                              label="Zip/Postal"
                              value={formik.values.customerAddresses[index].zipPostal}
                              onChange={(e: any) => {
                                handleZipCodeChange(e, index);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            margin="dense"
                            id="address"
                            name={`customerAddresses[${index}].addressType`}
                            label={`Type`}
                            disabled={billingAdded && formik.values.customerAddresses[index]?.addressType != '2'}
                            value={formik.values.customerAddresses[index].addressType}
                            onChange={(e: any) => {
                              formik.setFieldValue(`customerAddresses[${index}].addressType`, e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}>
                            {addressTypes.map((option: any) => {
                              return (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Grid>
                        <Grid item xs={2}>
                          <Box mt={1} ml={2}>
                            <Button
                              fullWidth
                              id="saveCustomerAddressBtn"
                              color="primary"
                              variant="contained"
                              size="medium"
                              onClick={() => {
                                handlePopoverClose();
                              }}>
                              Close
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Popover>
                </>
              );
            })}
        </Grid>
      </Grid>
    </>
  );
}
