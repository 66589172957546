import { Box, CardHeader, CircularProgress, IconButton, Menu, MenuItem, Theme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { amber, blue, green, orange, purple, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DOMPurify from 'dompurify';
import React, { ReactElement, useState } from 'react';

import Tag from 'common/components/tag';
import { useRouter } from 'common/hooks';
import { BroadCast, BroadCastType } from 'components/broadcast/models/broadcast';
import SmsIcon from '@mui/icons-material/Sms';
import MmsIcon from '@mui/icons-material/Mms';
import EmailIcon from '@mui/icons-material/Email';
import CardToolTipWrapper from './card-tooltip-wrapper';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme): any => ({
  card: {
    height: '193px',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'visible',
    '&:hover': {
      boxShadow: theme.shadows[5],
      '& $action': {
        visibility: 'visible',
      },
    },
  },
  action: {
    visibility: 'hidden',
    position: 'absolute',
    right: '6px',
    top: '4px',
    background: theme.palette.background.paper,
  },
  header: {
    height: '60px',
    paddingLeft: '32px',
    overflow: 'hidden',
    alignItems: 'start',
  },
  headerContent: {
    width: '100%',
    wordBreak: 'break-all',
  },
  content: {
    overflow: 'hidden',
    alignItems: 'start',
  },
  title: {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
  },
  body: {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '& img': {
      display: 'none',
    },
  },
  Draft: {
    display: 'inline-block',
    'font-weight': 'bold',
    hyphens: 'auto',
  },
  Sent: {
    display: 'inline-block',
    'font-weight': 'bold',
    hyphens: 'auto',
  },
  Deleted: {
    display: 'inline-block',
    'font-weight': 'bold',
    hyphens: 'auto',
  },
}));

export default function BroadCastCard({
  getLocationName,
  broadCast,
  broadCastType,
  onDuplicate,
  onDelete,
  onRestore,
  onCancel
}: {
  getLocationName(dealerLocationId: number): string | undefined;
  broadCast: BroadCast;
  broadCastType: BroadCastType | any;
  onDuplicate(id: number, type: BroadCastType): void;
  onDelete(id: number, type: BroadCastType): void;
  onRestore(id: number, type: BroadCastType): void;
  onCancel(id: number, type: BroadCastType): void;
}): ReactElement {
  const classes: any = useStyles();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleActionMenuClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleActionMenuClose = (): void => setAnchorEl(null);

  const handleCardClicked = (): void => {
    handleActionMenuClose();
    if (broadCastType === 'SMS') {
      router.push(`/broadcasts/${broadCast.id}?type=SMS`);
    } else if (broadCastType === 'MMS') {
      router.push(`/broadcasts/${broadCast.id}?type=MMS`);
    } else if (broadCastType === 'EMAIL') {
      router.push(`/broadcasts/${broadCast.id}?type=EMAIL`);
    }
  };

  const handleEdit = (): void => {
    handleActionMenuClose();
    if (broadCastType === 'SMS') {
      router.push(`/broadcasts/${broadCast.id}?type=SMS`);
    } else if (broadCastType === 'MMS') {
      router.push(`/broadcasts/${broadCast.id}?type=MMS`);
    } else if (broadCastType === 'EMAIL') {
      router.push(`/broadcasts/${broadCast.id}?type=EMAIL`);
    }
  };

  const handleDuplicate = (): void => {
    handleActionMenuClose();
    onDuplicate(broadCast.id, broadCastType);
  };

  const handleDelete = (): void => {
    handleActionMenuClose();
    onDelete(broadCast.id, broadCastType);
  };

  const handleRestore = (): void => {
    handleActionMenuClose();
    onRestore(broadCast.id, broadCastType);
  };
  const handleCancel = (): void => {
    handleActionMenuClose();
    onCancel(broadCast.id, broadCastType);
  };

  const borderStyleActive = `5px solid`;
  const borderColorDeactivated = red[500];
  const borderColorActive = green[500];
  const borderColorDraft = amber[500];
  const borderColorProgress = blue[500];
  const borderColorScheduled = purple[500];

  let npsColor;
  let surveyStatus = '';
  let currentBorderColor;
  if (broadCast.isSent === 1) {
    currentBorderColor = borderColorActive;
    surveyStatus = 'Sent';
    npsColor = green[500];
  } else if (broadCast.isSent == 0) {
    currentBorderColor = borderColorDraft;
    surveyStatus = 'Draft';
    npsColor = amber[500];
  } else {
    currentBorderColor = borderColorProgress;
    surveyStatus = 'Queued';
    npsColor = blue[500];
  }

  if (broadCast.isDeleted) {
    currentBorderColor = borderColorDeactivated;
    surveyStatus = 'Deleted';
    npsColor = red[500];
  }
  if (broadCast.isSent == 0 && broadCast.isSchedule) {
    currentBorderColor = borderColorScheduled;
    surveyStatus = 'Scheduled';
    npsColor = purple[500];
  }
  
  const extractContent = (s: string, space: boolean): string => {
    const span = document.createElement('span');
    span.innerHTML = s;
    if (space) {
      const children = span.querySelectorAll('*');
      for (let i = 0; i < children.length; i++) {
        if (children[i].textContent) children[i].textContent += ' ';
        else children[i].textContent += ' ';
      }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g, ' ');
  };

  return (
    <CardToolTipWrapper
      tooltip={broadCastType !== 'EMAIL' ? broadCast.body : ''}
      toolTipVisible={broadCastType !== 'EMAIL'}>
      <Card
        className={`${classes.card} broadcastCard`}
        onClick={handleCardClicked}
        style={{ borderBottom: borderStyleActive, borderColor: currentBorderColor }}>
        <CardHeader
          className={classes.header}
          style={{ paddingBottom: '22px' }}
          titleTypographyProps={{ className: classes.title }}
          classes={{ action: classes.action, content: classes.headerContent }}
          title={
            <div style={{ minHeight: '40px' }}>
              <span className="broadcastStatus">
                <Tag label={surveyStatus} color={npsColor} style={{ marginTop: '13px' }} />
              </span>
              <span className="broadcastTitle">{broadCast.title}</span>
            </div>
          }
          action={
            <IconButton
              style={{ padding: '8px', borderRadius: '0px' }}
              id="broadCastOptionBtn"
              className={classes.action}
              onClick={handleActionMenuClick}>
              <MoreVertIcon fontSize="small" />
            </IconButton>
          }
          subheaderTypographyProps={{ variant: 'caption', style: { wordBreak: 'break-word' } }}
          subheader={
            <>
              <Typography
                display="inline"
                style={{
                  color: broadCast.type === 'SMS' ? '#1b80ae' : broadCast.type === 'MMS' ? '#04c227' : '#00acb0',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  textAlign: 'right',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}>
                {broadCast.type === 'SMS' ? <SmsIcon /> : broadCast.type === 'MMS' ? <MmsIcon /> : <EmailIcon />}
                {broadCast.type}
              </Typography>
              <Typography style={{ textAlign: 'right' }}>{getLocationName(broadCast.dealerLocationId)}</Typography>
            </>
          }
          // subheader={getLocationName(broadCast.dealerLocationId)}
        />

        <CardContent className={`${classes.content} broadcastCardContent`}>
          {/* {( broadCastType != 'EMAIL' && broadCast.isSent === -1) && <CircularProgress size={20}></CircularProgress>} */}
          {broadCast.isSchedule === false ? (
            <Typography
              className={classes.body}
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: extractContent(DOMPurify.sanitize(broadCast.body), true),
              }}
            />
          ) : (
            <Box textAlign={'center'}>
              <CalendarTodayIcon />
              <Typography style={{ fontWeight: 'bold' }}>Scheduled to send</Typography>
              <Typography>
                {broadCast.scheduleDate &&  moment.utc(broadCast.scheduleDate).local().format('M-D-YYYY, h:mm A')}
              </Typography>
            </Box>
          )}
        </CardContent>
        <Menu
          id="broadcastCardMenu"
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClick={(e: any): void => e.stopPropagation()}
          onClose={handleActionMenuClose}>
          {!broadCast.isDeleted && (
            <div>
              <MenuItem dense id="editBroadcast" button onClick={handleEdit}>
                <Typography color="textSecondary">Edit</Typography>
              </MenuItem>
              <MenuItem dense id="duplicateBroadcast" button onClick={handleDuplicate}>
                <Typography color="textSecondary">Duplicate</Typography>
              </MenuItem>
              <MenuItem dense id="deleteBroadcast" button disabled={surveyStatus !== 'Draft' && surveyStatus !== 'Scheduled' } onClick={handleDelete}>
                <Typography color="textSecondary">Delete</Typography>
              </MenuItem>
              {broadCast.isSchedule && (
                <MenuItem dense id="deleteBroadcast" button disabled={surveyStatus !== 'Draft' && surveyStatus !== 'Scheduled' } onClick={handleCancel}>
                <Typography color="textSecondary">Cancel</Typography>
              </MenuItem>
              )}
            </div>
          )}

          {broadCast.isDeleted && (
            <MenuItem dense id="restoreBroadcast" button onClick={handleRestore}>
              <Typography color="textSecondary">Restore</Typography>
            </MenuItem>
          )}
        </Menu>
      </Card>
    </CardToolTipWrapper>
  );
}
