import { BroadcastResponse, RescheduleBroadcast, SendBroadcastTestMessageRequest, SendBroadcastTestMessageResponse } from './../models/broadcast';
import { destroy, get, post, put } from 'api';
import axios from 'axios';
import { BroadCast, BroadCastStatus, BroadCastType, BroadCastTypeFilter, EmailMessage } from 'components/broadcast/models/broadcast';
import config from 'config';
import { Analytics, SpecificAnalytics } from '../models/analytics';
import { MessageTemplate } from '../models/message-template';
import { MergeTag } from '../models/merge-tag';
import { DateRange } from '@mui/lab/DateRangePicker';
import { BaseResponse } from 'components/messaging/api/messaging-api';

const messagingUrl = config.get('api.messaging');
const messaging2Url = config.get('api.messaging2');


export const BroadCastAPIV2 = {
  getBroadCasts: async (
    dmsDealerIdCrm: number,
    type: BroadCastTypeFilter,
    status: BroadCastStatus,
    dealerLocationId?: number,
    pageSize?: number,
    pageNumber?: number
  ): Promise<BroadcastResponse> => {
    const url = `${messagingUrl}/broadcast/getBroadcast`;
    const {data} = await post<BroadcastResponse>(url,{dmsDealerIdCrm,type,status,dealerLocationId,pageSize,pageNumber});
    return data;
  },
  createBroadcast: async (type: BroadCastType, title: string, dealerLocationId?: number): Promise<BroadCast> => {
    switch (type) {
      case 'SMS': {
        const url = `${messagingUrl}/smsMessages`;
        const { data } = await post<BroadCast>(url, { title, dealerLocationId });
        return data;
      }
      case 'MMS': {
        const url = `${messagingUrl}/mmsMessages`;
        const { data } = await post<BroadCast>(url, { title, dealerLocationId });
        return data;
      }
      case 'EMAIL': {
        const url = `${messagingUrl}/emailMessages`;
        const { data } = await post<BroadCast>(url, { title, dealerLocationId });
        return data;
      }
      default:
        throw new Error('Invalid Broadcast type');
    }
  },
  getBroadcast: async (id: number, type: BroadCastType): Promise<BroadCast> => {
    switch (type) {
      case 'SMS': {
        const url = `${messagingUrl}/smsMessage/${id}`;
        const { data } = await get<BroadCast>(url);
        return data;
      }
      case 'MMS': {
        const url = `${messagingUrl}/mmsMessage/${id}`;
        const { data } = await get<BroadCast>(url);
        return data;
      }
      case 'EMAIL': {
        const url = `${messagingUrl}/emailMessage/${id}`;
        const { data } = await get<BroadCast>(url);
        return data;
      }
      default:
        throw new Error('Invalid Broadcast type');
    }
  },
  updateBroadcast: async (broadcast: BroadCast, type: BroadCastType): Promise<BroadCast> => {
    switch (type) {
      case 'SMS': {
        const url = `${messagingUrl}/smsMessages`;
        await put<BroadCast>(url, broadcast);
        return broadcast;
      }
      case 'MMS': {
        const url = `${messagingUrl}/mmsMessages`;
        await put<BroadCast>(url, broadcast);
        return broadcast;
      }
      case 'EMAIL': {
        const url = `${messagingUrl}/emailMessages`;
        await put<BroadCast>(url, broadcast);
        return broadcast;
      }
      default:
        throw new Error('Invalid Broadcast type');
    }
  },
  duplicateBroadcast: async (id: number, type: BroadCastType): Promise<{ id: number }> => {
    switch (type) {
      case 'SMS': {
        const url = `${messagingUrl}/smsMessages/${id}/duplicate`;
        const { data } = await post(url);
        return data;
      }
      case 'MMS': {
        const url = `${messagingUrl}/mmsMessages/${id}/duplicate`;
        const { data } = await post(url);
        return data;
      }
      case 'EMAIL': {
        const url = `${messagingUrl}/emailMessages/${id}/duplicate`;
        const { data } = await post(url);
        return data;
      }
      default:
        throw new Error('Invalid Broadcast type');
    }
  },
  deleteBroadcast: async (id: number, type: BroadCastType): Promise<BroadCast> => {
    switch (type) {
      case 'SMS': {
        const url = `${messagingUrl}/smsMessages/${id}/delete`;
        const { data } = await destroy<BroadCast>(url);
        return data;
      }
      case 'MMS': {
        const url = `${messagingUrl}/mmsMessages/${id}/delete`;
        const { data } = await destroy<BroadCast>(url);
        return data;
      }
      case 'EMAIL': {
        const url = `${messagingUrl}/emailMessages/${id}/delete`;
        const { data } = await destroy<BroadCast>(url);
        return data;
      }
      default:
        throw new Error('Invalid Broadcast type');
    }
  },
  restoreBroadcast: async (id: number, type: BroadCastType): Promise<BroadCast> => {
    switch (type) {
      case 'SMS': {
        const url = `${messagingUrl}/smsMessages/${id}/restore`;
        const { data } = await post<BroadCast>(url);
        return data;
      }
      case 'MMS': {
        const url = `${messagingUrl}/mmsMessages/${id}/restore`;
        const { data } = await post<BroadCast>(url);
        return data;
      }
      case 'EMAIL': {
        const url = `${messagingUrl}/emailMessages/${id}/restore`;
        const { data } = await post<BroadCast>(url);
        return data;
      }
      default:
        throw new Error('Invalid Broadcast type');
    }
  },
  deleteScheduledBroadcast: async (id: number, type: BroadCastType): Promise<boolean> =>{
    const url = `${messagingUrl}/broadcast/removeScheduledBroadcast`;
    const { data } = await post(url,{ broadCastId:id, broadcastType:type })
    return data;
  },
  sendBroadcast: async (
    broadcast: BroadCast,
    type: BroadCastType,
    segmentIds: number[],
    dealerLocationId?: number,
    dmsDealerId?: number,
    IsSchedule?: boolean
  ): Promise<BroadCast> => {
    switch (type) {
      case 'SMS': {
        const url = `${messaging2Url}/SendMessageToSegment?dealerLocationId=${dealerLocationId || ''}&dmsDealerId=${
          dmsDealerId || ''}`;
        const { data } = await post(url, {
          Id: broadcast.id,
          message: broadcast.body,
          segmentIds,
          type : 'SMS',
          IsSchedule
        });
        return data;
      }
      case 'MMS': {
        const url = `${messaging2Url}/SendMessageToSegment?dealerLocationId=${dealerLocationId || ''}&dmsDealerId=${
          dmsDealerId || ''
        }`;
        const { data } = await post(url, {
          Id: broadcast.id,
          message: broadcast.body,
          mediaLinks: broadcast.mmsMedia?.map((x) => x.mediaUrl),
          segmentIds,
          type : 'MMS',
          IsSchedule
        });
        return data;
      }
      case 'EMAIL': {
        const url = `${messaging2Url}/emailMessages/sendEmailToSegments?dealerLocationId=${dealerLocationId || ''}&dmsDealerId=${dmsDealerId || ''}`;
        const emailRequest = {
          id: broadcast.id,
          segmentIds,
          dealerLocationid: dealerLocationId,
          dmsDealerId,
          IsSchedule
        };
        const { data } = await post<BroadCast>(url, emailRequest);
        return data;
      }
      default:
        throw new Error('Invalid Broadcast type');
    }
  },
  sendScheduledBroadcast: async (
    broadcast: BroadCast,
    type: BroadCastType,
    segmentIds: number[],
    scheduledTime: Date,
    dealerLocationId?: number,
    dmsDealerId?: number,
  ): Promise<BroadCast> => {
    switch (type) {
      case 'SMS': {
        const url = `${messagingUrl}/ScheduleMessagesToSegments`;
        const { data } = await post(url, {
          Id: broadcast.id,
          message: broadcast.body,
          segmentIds,
          type : 'SMS',
          // dealerId:dealerLocationId,
          // dmsDealerId,
          scheduledTime,
          dealerLocationId
        });
        return data;
      }
      case 'MMS': {
        const url = `${messagingUrl}/ScheduleMessagesToSegments`;
        const { data } = await post(url, {
          Id: broadcast.id,
          message: broadcast.body,
          mediaLinks: broadcast.mmsMedia?.map((x) => x.mediaUrl),
          segmentIds,
          type : 'MMS',
          // dealerId:dealerLocationId,
          // dmsDealerId,
          dealerLocationId,
          scheduledTime
        });
        return data;
      }
      // Need to be done against Email yet
      case 'EMAIL': {
        const url = `${messagingUrl}/ScheduleMessagesToSegments`;
        const emailRequest = {
          id: broadcast.id,
          segmentIds,
          // dealerId: dealerLocationId,
          dmsDealerId,
          type:'EMAIL',
          scheduledTime,
          dealerLocationId
        };
        const { data } = await post<BroadCast>(url, emailRequest);
        return data;
      }
      default:
        throw new Error('Invalid Broadcast type');
    }
  },
  rescheduleBroadcast: async (request: RescheduleBroadcast): Promise<any> =>{
    const url = `${messagingUrl}/RescheduleMessagesToSegments`;
    const { data } = await post<any>(url,request);
    return data;
  }
  ,
  shortenUrl: async (currentUrl: string): Promise<string> => {
    const url = `${messagingUrl}/shortenUrl`;
    const { data } = await post<string>(url, { Url: currentUrl });
    return data;
  },
    getMergeTags: async (refScreen: string): Promise<MergeTag[]> => {
    const url = `${messagingUrl}/GetMergeTagsAutomation/${refScreen}`;
    const { data } = await get<MergeTag[]>(url);
    return data;
  },
  getMessageTemplates: async (): Promise<MessageTemplate[]> => {
    const url = `${messagingUrl}/v2/message-snippets`;
    const { data } = await get<MessageTemplate[]>(url);
    return data;
  },
  updateMessageTemplates: async (messageTemplates: MessageTemplate[]): Promise<MessageTemplate[]> => {
    const url = `${messagingUrl}/message-snippets-bulk-update`;
    const { data } = await put<MessageTemplate[]>(url, messageTemplates);
    return data;
  },
  getMessageTemplate: async (id: number): Promise<MessageTemplate> => {
    const url = `${messagingUrl}/message-snippets/${id}`;
    const { data } = await get<MessageTemplate>(url);
    return data;
  },
  createMessageTemplate: async (req:any, departmentId: number): Promise<MessageTemplate> => {
    const url = `${messagingUrl}/message-snippets`;
    const { data } = await post<MessageTemplate>(url, { id: 0, body: req.body, departmentId, title: req.title });
    return data;
  },
  updateMessageTemplate: async (template: MessageTemplate): Promise<MessageTemplate> => {
    const url = `${messagingUrl}/message-snippets`;
    const { data } = await put<MessageTemplate>(url, template);
    return data;
  },
  deleteMessageTemplate: async (templateId: number): Promise<MessageTemplate> => {
    const url = `${messagingUrl}/message-snippets/${templateId}`;
    const { data } = await destroy<MessageTemplate>(url);
    return data;
  },
  unsubscribeEmail: async (token: string): Promise<EmailMessage> => {
    let url = `${messagingUrl}/emailMessages/unsubscribeEmail`;
    url = url.toString().replace("v2/", "");
    const { data } = await post(url, { token: token });
    return data;
  },
  getBroadCastsAnalytics: async (
    dmsDealerIdCrm: number,
    type: string,
    dateRangeFilter: DateRange<Date>,
    dealerLocationId?: number,
  ): Promise<Analytics[]> => {
    //get data using post request empty url
    const url = `${messagingUrl}/analytics`;
    const { data } = await post<Analytics[]>(url, {
      dmsDealerIdCrm,
      type,
      DateFrom : dateRangeFilter[0],
      DateTo : dateRangeFilter[1],
      dealerLocationId,
    });
    console.log(data);
    return data;
  },
  getBroadcastSpecificAnalytics : async (broadcastId : number, messagetype : string, analyticsType : string, usageHistoryId: number ) : Promise<SpecificAnalytics[]> =>  {
    const url = `${messagingUrl}/specific-analytics`;
    const { data } = await post<SpecificAnalytics[]>(url, { messageId : broadcastId, messagetype, analyticsType, usageHistoryId });
    console.log(data);
    return data;
  }, 
  sendBroadcastTestMessage: async (testMessageRequest:SendBroadcastTestMessageRequest): Promise<BaseResponse<SendBroadcastTestMessageResponse>> => {

    const url = `${messagingUrl}/broadcast/sendBroadcastTestMessage`;
    const { data } = await post<BaseResponse<SendBroadcastTestMessageResponse>>(url, testMessageRequest);
    return data;
  },

};

export default BroadCastAPIV2;
