import config from 'config';
import { Box, Button, Container, Drawer, Grid, MenuItem, TextField, Paper, Typography, Chip, Checkbox, FormControlLabel, List, Divider, ListItemIcon, ListItemText, Popover, InputAdornment, IconButton, Tab, Tooltip, colors } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import useLocations from 'common/hooks/useLocations';
import { isNotNullOrUndefined } from 'common/utils';
import { CustomerCategory } from 'components/customer-groups/models/customer-category';
import CustomerGroup from 'components/customer-groups/models/customer-group';
import { Manufacturer } from 'components/customer-groups/models/manufacturer';
import { useFormik } from 'formik';
import Provinces from 'provinces';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'app';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as Yup from 'yup';
import { FilterName } from '../../../models/filter';
import CustomerAPI from 'components/customer/api/CustomerAPI';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CustomerSettingsApi from 'components/setting/api/customer-settings-api';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AppSnackBar from 'components/snackbar/snackbar';
import { format, parseISO } from 'date-fns';
import { salesStages } from 'common/utils/salesStages';

const US_CANADA_PROVINCES = Provinces.filter((province) => province.country === 'US' || province.country === 'CA');

const useStyles = makeStyles({
  button1: {
    margin: "2px",
    padding: "0px 10px",
    fontSize: '13px',
    backgroundColor: '#EAFAF1',
    color: 'brown',
    border: '1px solid gray',
    // fontWeight: 'bold'
  },


});

export interface FilterType {
  label: string;
  value: FilterName;
  component: any;
}

export const PeriodOptions = [
  {
    value: 1,
    label: 'Months',
    formatLabel: (value: number, value2: number): string => {
      if (value2 == null) value2 = 0;
      let v = `[${value2}-${value}]`;
      if (value2 === 0) v = `${value}`;
      let res = `For ${v} month`;
      if (value > 1) res = res.replace('month', 'months');
      return res;
      // if (value === 1) {
      //   return `For ${value} month`;
      // }
      // return `For [${value}] months`;
    },
  },
  {
    value: 2,
    label: 'Days',
    formatLabel: (value: number): string => {
      if (value === 1) {
        return `For ${value} Day`;
      }
      return `For ${value} days`;
    },
  },
];

export const InvoiceOptions = [
  { value: 0, label: 'Select All Options' },
  { value: 2, label: 'Service Only' },
  { value: 3, label: 'Parts Only' },
  { value: 4, label: 'Equipment Only' },
  { value: 5, label: 'No Invoice'}
];

export const NoSaleOptions = [
  {
    label: 'No Part Sale in months',
    value: 10,
    formatLabel: 'No Part Sale in {Month} months',
  },
  {
    label: 'No WG Sale in months',
    value: 20,
    formatLabel: 'No WG Sale in {Month} months',
  },
  {
    label: 'No Service in months',
    value: 30,
    formatLabel: 'No Service in {Month} months',
  },
];

export default function CustomerGroupFilterDrawer({
  open,
  customerGroup,
  onClose,
  addFilter,
  manufacturers,
  customerCategories,
}: {
  open: boolean;
  customerGroup?: CustomerGroup;
  onClose(): void;
  addFilter(data: any, elUploadFile: any): void;
  manufacturers: Manufacturer[];
  customerCategories: CustomerCategory[];
}): React.ReactElement | null {
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const defaultDealerId = useAppSelector((state) => state.user.dealerId);
  const { locations } = useLocations(dmsDealerIdCrm);
  const [customerTags, setCustomerTags] = React.useState<[]|any>([]);
  const [allInvoiceOptionsSelected, setAllInvoiceOptionsSelected] = React.useState<boolean>(customerGroup?.invoiceTypes?.length === 4)
  const [InvoiceTimeFrameTabValue, setInvoiceTimeFrameTabValue] = useState(customerGroup?.invoiceCalendarOption? "2" : "1");
  const [crossSellTabValue, setCrossSellTabValue] = useState(customerGroup?.noSaleCalendarOption? "2" : "1");
  const [crossSellError, setCrossSellError] = useState<any>('')

  const schema = Yup.object<any>().shape<any>({
    dealerId: Yup.number().min(0).nullable(true),
    period: Yup.number().oneOf([1, 2]).nullable(true),
    periodValue: Yup.number().min(0, 'Must be greater than 0').nullable(true),
    fromMonth: Yup.number().nullable(true),
    invoiceType: Yup.number().oneOf([0, 1, 2, 3, 4, 5]).nullable(true),
    manufacturerCodes: Yup.array().nullable(true),
    customerCategoryIds: Yup.array().nullable(true),
    zipCodes: Yup.string().nullable(true).nullable(true),
    zipCodesInRadius: Yup.number().min(0).nullable(true),
    states: Yup.array().nullable(true),
    noSaleOption: Yup.number()
    .oneOf(NoSaleOptions.map((x) => x.value))
      .nullable(true),
    noSaleOptionValue: Yup.number().max(1000).min(1).nullable(true),
    phoneNumber: Yup.string().nullable(true),
    partNumber: Yup.array().nullable(true),
    modelNumber: Yup.string().nullable(true),
    customerTag: Yup.string().nullable(true),
    amountSpent: Yup.number().nullable(true),
    invoiceTypes: Yup.array().nullable(false).min(1, 'Please enter atleast one invoice option'),
    invoiceFrom: Yup.date()
    .nullable(true)
    .test('valid-date-range', 'Invalid date', (value) => {
      return validateDateRange(value) 
    })
    .test('both-or-none', 'Please Enter Start date', function (value) {
      const { invoiceTo } = this.parent;
      return !invoiceTo || !!value; 
    })
    .test('less-than-end-date', 'Start date must be less than end date', function (value) {
      const { invoiceFrom, invoiceTo } = this.parent;
      if (!invoiceFrom || !invoiceTo) return true
      return new Date(invoiceFrom) <= new Date(invoiceTo)
    })
    .test('no-future-date', 'Start date cannot be in the future', (value) => {
      return !value || new Date(value) <= new Date(); 
    }),
    invoiceTo: Yup.date()
    .nullable(true)
    .test('valid-date-range', 'Invalid date', (value) => {
      return validateDateRange(value) 
    })
    .test('both-or-none', 'Please Enter End date', function (value) {
      const { invoiceFrom } = this.parent;
      return !invoiceFrom || !!value;
    })
    .test('no-future-date', 'End date cannot be in the future', (value) => {
      return !value || new Date(value) <= new Date(); 
    }),
    noSaleFrom: Yup.date()
    .nullable(true)
    .test('valid-date-range', 'Invalid date', (value) => {
      return validateDateRange(value) 
    })
    .test('both-or-none', 'Please Enter Start date', function (value) {
      const { noSaleFrom, noSaleTo } = this.parent;
      return !noSaleTo || !!value; 
    })
    .test('less-than-end-date', 'Start date must be less than end date', function (value) {
      const { noSaleFrom, noSaleTo } = this.parent;
      if (!noSaleFrom || !noSaleTo) return true
      return new Date(noSaleFrom) <= new Date(noSaleTo)
    })
    .test('no-future-date', 'Start date cannot be in the future', (value) => {
      return !value || new Date(value) <= new Date();
    }),
  
    noSaleTo: Yup.date()
    .nullable(true)
    .test('valid-date-range', 'Invalid date', (value) => {
      return validateDateRange(value)
    })
    .test('both-or-none', 'Please enter End date', function (value) {
      const { noSaleFrom } = this.parent;
      return !noSaleFrom || !!value; 
    })
    .test('no-future-date', 'End date cannot be in the future', (value) => {
      return !value || new Date(value) <= new Date(); 
    })
    // parts: Yup.string().nullable(true)
  });

  React.useEffect(() => {
    async function fetchLookups() {
      let tags: any[] = []
      let _customerTags = await CustomerAPI.GetCustomerTags();
      const data = _customerTags.flatMap(x=>x.tag);
      setCustomerTags(data);
      const staticTags = await CustomerSettingsApi.getCustomerTags();
      tags = staticTags

      let obj : any = {
        title: 'Miscellaneous', 
        options: _customerTags
      }
      tags.push(obj)
      setStaticCustomerTags(tags);
    }
    fetchLookups();
  }, []);
  const initialValues: any = {
    dealerId: customerGroup?.forAllLocation ? 0 : customerGroup?.dealerId || '',
    locations: [],
    invoiceType: isNotNullOrUndefined(customerGroup?.invoiceType) ? customerGroup?.invoiceType : '',
    period: customerGroup?.period || '',
    periodValue: customerGroup?.periodValue || '',
    fromMonth: customerGroup?.fromMonth || '',
    invoiceFrom: customerGroup?.invoiceFrom? format(parseISO(customerGroup.invoiceFrom), 'yyyy-MM-dd') : '',
    invoiceTo: customerGroup?. invoiceTo? format(parseISO(customerGroup.invoiceTo), 'yyyy-MM-dd') : '',
    noSaleFrom: customerGroup?.noSaleFrom? format(parseISO(customerGroup.noSaleFrom), 'yyyy-MM-dd') : '',
    noSaleTo: customerGroup?.noSaleTo? format(parseISO(customerGroup.noSaleTo), 'yyyy-MM-dd') : '',
    noSaleCalendarOption: customerGroup?.noSaleCalendarOption? customerGroup.noSaleCalendarOption : false ,
    invoiceCalendarOption: customerGroup?.invoiceCalendarOption? customerGroup.invoiceCalendarOption : false,
    manufacturerCodes: customerGroup?.manufacturerCodes
      ? manufacturers.filter(
        (x) => customerGroup.manufacturerCodes && customerGroup.manufacturerCodes.indexOf(x.manufacturerId) > -1,
      )
      : [],
    customerCategoryIds: customerGroup?.customerCategoryIds
      ? customerCategories.filter(
        (x) =>
          customerGroup.customerCategoryIds && customerGroup.customerCategoryIds.indexOf(x.customerCategoryId) > -1,
      )
      : [],
    zipCodes: customerGroup?.zipCodes || '',
    zipCodesInRadius: customerGroup?.zipCodesInRadius || '',
    states: customerGroup?.states
      ? US_CANADA_PROVINCES.filter((x) => customerGroup.states && x.short && customerGroup.states.indexOf(x.short) > -1)
      : [],
    noSaleOption: customerGroup?.noSaleOption || '',
    noSaleOptionValue: customerGroup?.noSaleOptionValue == 0 ? '' : (customerGroup?.noSaleOptionValue || ''),
    phoneNumber: '',
    partNumber: customerGroup?.parts??[],
    modelNumber: '',
    customerTag: customerGroup?.customerTag || '',
    amountSpent: customerGroup?.amountSpent || 0,
    salesStageId: customerGroup?.salesStageId? customerGroup.salesStageId : null,
    noInvoice: customerGroup?.noInvoice? customerGroup.noInvoice : null,
    invoiceTypes: customerGroup?.invoiceTypes? customerGroup.invoiceTypes : []
  };
  const [phoneArray, setPhoneArray] = React.useState<[]|any>(customerGroup?.phoneNumber || []);
  const classes: any = useStyles();
  const [uploadText, SetUploadText] = React.useState<string>('');
  const [searchInput, setSearchInput] = useState('');
  const [showSnackBar] = AppSnackBar.useSnackBar();
  // #region PartNumber fields

const [partNumberSearchQuery, setPartNumberSearchQuery] = useState('');
const [partNumberSuggestions, setPartNumberSuggestions] = useState<string[]>([]);
const [partNumberLoading, setPartNumberLoading] = useState(false);
const [minimumSpent, setMinimumSpent] = useState<any>(customerGroup?.amountSpent? customerGroup.amountSpent : null)
// #endregion PartNumber fields

const [staticCustomerTags, setStaticCustomerTags] = useState<any[]>([]);
const [popoverState, setPopoverState] = useState<boolean>(false);
const selectAllOption = { dealerId: null, locationName: "Select All Locations" };
const [allLocationsSelected, setAllLocationsSelected] = useState<boolean | undefined>(false)
  
const formik = useFormik({
    initialValues,
    onSubmit: (data: any): any => {
      const numberValidated = validatePhoneNumber(data.phoneNumber.replace(/[^0-9]/g, ""))
      const periodMonthValidated = validatePeriodMonth(data.periodValue)

      if (data.noSaleCalendarOption && data.noSaleFrom && data.noSaleTo && !data.noSaleOption) {
        formik.setFieldError('noSaleOption', 'Required')
        return;
      }
      let dateError = false
      const dateFields = ['invoiceFrom', 'invoiceTo', 'noSaleFrom', 'noSaleTo'];
      dateFields.forEach(field => {
        if (!validateDateRange(data[field])) {
          formik.setFieldError(field, 'Invalid Date');
          dateError = true
        }
      });
      if (!locationError() && numberValidated && periodMonthValidated && !dateError) {
      let dealerIds : any[] = []
      data.locations.forEach((location: any) => {
        dealerIds.push(location.dealerId.toString())
      })
      data.locations = dealerIds
      const submittedData: any = { ...data };
      if (data.dealerId === 0) {
        submittedData.dealerId = defaultDealerId;
        submittedData.forAllLocation = 1;
      } else if (data.dealerId) {
        submittedData.dealerId = data.dealerId;
        submittedData.forAllLocation = 0;
      }
      submittedData.manufacturerCodes = data.manufacturerCodes.map((x: Manufacturer) => x.manufacturerId);
      submittedData.customerCategoryIds = data.customerCategoryIds.map((x: CustomerCategory) => x.customerCategoryId);
      submittedData.states = data.states.map((x: Province) => x.short);
      submittedData.phoneNumber = phoneArray.join(',');
      //submittedData.phoneNumber = data.phoneNumber && JSON.stringify(data.phoneNumber);
      //submittedData.phoneNumber = data.phoneNumber ? sanitizeTelePhoneInput(data.phoneNumber) : data.phoneNumber;
      let elUpload: any = document.getElementById('inputFileUpload');
      if (uploadText == '') elUpload = null;
      // submittedData.uploadFile=elUpload;
      addFilter(submittedData, elUpload);
    }
    },
    validationSchema: schema,
    enableReinitialize: true,
  });

  const validateDateRange = (value: any) => {
    if (!value) return true; // Valid if null
    const date = new Date(value);
    if (isNaN(date.getTime())) return false;
    const minDate = new Date('1900-01-01');
    const maxDate = new Date('2099-12-30');
    return date >= minDate && date <= maxDate;
  }; 
  // #region PartNumber Methods
  React.useEffect(() => {
    let locationObjects : any[]= []
    locations?.forEach((availableLocation) => {
      customerGroup?.locations?.forEach((location) => {
        if (availableLocation.dealerId.toString() == location) {
           locationObjects.push(availableLocation)
        }
      })
    })
    formik.setFieldValue('locations', locationObjects)
  }, [locations])

  React.useEffect(() => {
    if (formik.values.invoiceTypes.length === 4) {
      setAllInvoiceOptionsSelected(true)
    } else {
      setAllInvoiceOptionsSelected(false)
    }
    if (onlyNoInvoice()) {
      formik.setFieldValue('locations', locations)
    }
  }, [formik.values.invoiceTypes])  
  
  useEffect(() => {
    setAllLocationsSelected(formik.values.locations?.length == locations?.length)
 }, [formik.values.locations])

    const cancelLastPartNumberSuggestionsCall = async () => {
        await CustomerAPI.cancelPartNumberToken();
    };

    const fetchPartNumberSuggestions = async (searchKeyword:string) => {
        setPartNumberLoading(true);
        let pageSize = 100,pageNumber = 1;
        try {
            const response: any = await CustomerAPI.getModelNumbers(searchKeyword, pageNumber, pageSize);
            let data = response.map((x: any)=>x.modelNumber);
            setPartNumberSuggestions(data);
        } catch (error) {
            console.error('Error fetching partNumberSuggestions:', error);
        }
        finally {
            setPartNumberLoading(false);
        }
    };

    useEffect(() => {
        // Fetch partNumberSuggestions only if the partNumberSearchQuery has at least 2 characters
        if (partNumberSearchQuery.trim().length >= 2) {
            fetchPartNumberSuggestions(partNumberSearchQuery.trim());
        } else {
            cancelLastPartNumberSuggestionsCall()
            setPartNumberSuggestions([]);
        }
    }, [partNumberSearchQuery]);

    useEffect(() => {
      if (formik.values.invoiceType == 5) {
        formik.setValues({
          ...formik.values,
          dealerId: 0,
          fromMonth: '',
          periodValue: '',
          manufacturerCodes: [],
          noSaleOption: '',
          noSaleOptionValue: '',
          partNumber: [],
          amountSpent: 0,
        });
      }
    }, [formik.values.invoiceType])
  
    const handlePartNumberInputChange = (event:any, newValue:any,reason:any) => {
        //freesolo empty value case handled
        if(reason === 'create-option')
        {
            //comment for reference -> reason values: clear, remove-option, create-option
            // Remove any empty values from the selection
            const nonEmptyValues = newValue.filter((value:string) => value !== null && value.trim() !== '');
        
            // Update the state only if there are non-empty values
            if (nonEmptyValues.length > 0) {
                formik.setFieldValue('partNumber', nonEmptyValues);
            }
        }
        else
        {
            formik.setFieldValue('partNumber', newValue);
        }
        // empty the suggestions on selection
        setPartNumberSuggestions([]);
    }

  // #endregion PartNumber Methods

  function handleAmountSpent(): void {
    let minimumAmountSpent : any = minimumSpent;
      if (isNaN(minimumSpent)) {
      formik.setFieldValue('amountSpent', 0);
      return;
    }  
    if (Number.isInteger(parseFloat(minimumAmountSpent))) {
      minimumAmountSpent = parseFloat(minimumAmountSpent).toFixed(2);
    }
    formik.setFieldValue('amountSpent', minimumAmountSpent);
    setMinimumSpent(minimumAmountSpent);
  }

  function handleMinimumSpentState (event: any) {
    if (!hasMoreThanTwoDecimalPlaces(event.target.value)){
    setMinimumSpent(event.target.value)
    }
  }
  const hasMoreThanTwoDecimalPlaces = (value: string): boolean => {
    const parts = value.split('.');
    if (parts.length === 2) {
      return parts[1].length > 2;
    }
      return false;
  };

  function handleManufacturerChange(values: any): void {
    formik.setFieldValue('manufacturerCodes', values);
  }

  function handleCustomerCategoryChange(values: any): void {
    formik.setFieldValue('customerCategoryIds', values);
  }

  function handleStateChange(values: Province[]): void {
    formik.setFieldValue('states', values);
  }
  function handleLocationChange(values: any[]) {
    if (values.some((v) => v.dealerId == null)) {
      formik.setFieldValue("locations", locations);
      setAllLocationsSelected(true);
    } else {
      formik.setFieldValue("locations", values);
      setAllLocationsSelected(false);
    }
  }

  function handleInvoiceTypesChange(values: any[]) {
    // Check if "All Invoices" is selected
    const allInvoicesSelected = values.some(value => value.value === 0);
    const otherValues = InvoiceOptions.filter(option => [2, 3, 4, 5].includes(option.value));

    if (allInvoicesSelected) {
      // If "All Invoices" is selected, include it and the other values
      values = [...otherValues];
    } else {
      // If "All Invoices" is not selected, remove it from the values
      values = values.filter(value => value.value !== 0);
    }
    formik.setFieldValue('invoiceTypes', values);
  }
  function FileUploadClick() {
    let el = document.getElementById('inputFileUpload');
    if (el != null) {
      el.click();
    }
  }

  function ClearFileUploadClick() {
    // let el = document.getElementById('inputFileUpload');
    // el.value='';
    SetUploadText('');
  }
  function inputFileUploadOnchange(e: any) {
    let xPath = 'FilUpload-01.txt';
    let el: any = document.getElementById('inputFileUpload');
    let customerUrl = config.get('api.customer');
    let url = `${customerUrl}/UploadSegmentCustomerFile/0/0`;
    let formData = new FormData();
    formData.append("file", el.files[0]);
    // .post('http://localhost:7071/api/UploadSegmentCustomerFile/0/0', formData, {
    axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => {
        SetUploadText(res.data)
      })
  }
  
  function FormatPhoneNumber(num: string){
    if(num.length <0 ) return;
    const formattedNumber = num.length > 0 ? `(${num.slice(0, 3)}${num.length > 3 ? ') '+num.slice(3, 6):''}${num.length > 6 ? '-'+num.slice(6):''}`:'';
    return formattedNumber;
  }

  function handlePhoneTagDelete(num: string, index: number ){
    let newArray = [...phoneArray];
    newArray.splice(index,1);
    setPhoneArray(newArray);
  }

  const validateDate = (date: any) => {
    const validDateRegex = /^\d{4}-\d{2}-\d{2}$/; // Matches YYYY-MM-DD format
    return validDateRegex.test(date) || !date
  }
  const handleTabChange = (event: any, newValue: any) => {
    formik.setFieldValue('invoiceCalendarOption', newValue == "2"? true : false)
    setInvoiceTimeFrameTabValue(newValue);
  };

  const handleCrossSellTabChange = (event: any, newValue: any) => {
    formik.setFieldValue('noSaleCalendarOption', newValue == "2"? true : false)
    setCrossSellTabValue(newValue)
  }

  const handleSearchChange = (event: any) => {
    setSearchInput(event.target.value);
  };

  const filteredTags = (title: any) => {
    if (title.title !== 'Miscellaneous'){
    if (!searchInput) return title.customerTagOptions;
    return title.customerTagOptions.filter((option: any) =>
      option.tag.toLowerCase().includes(searchInput.toLowerCase())
    );
  } 
    if (!searchInput) {return title.options}
    else{
    return title.options && title.options.filter((option: any) =>
      option.tag.toLowerCase().includes(searchInput.toLowerCase())
    );
  }
  };

  const locationError = () => {
    if (formik.values.locations?.length == 0 && !(formik.values.invoiceTypes.length == 1 && formik.values.invoiceTypes[0].value == 5)) {
      return true;
    }
    return false;
  }

  const onlyNoInvoice = () => {
    if (formik.values.invoiceTypes?.length == 1 && formik.values.invoiceTypes[0].value == 5) {
      return true;
    }
    return false;
  }

  const validatePhoneNumber = (number: any) => {
    if((number.length) % 10 != 0){
      formik.setFieldError('phoneNumber','Incorrect phone number')
      return false;
    }
    return true;
  }

  const validatePeriodMonth = (number: any) => {
    if (Number(number) < 0 || Number(number) > 255) {
      formik.setFieldError('periodValue', 'Your input is invalid. Please enter a value between 0 and 255');
      return false;
    }
    // Clear any existing error if the number is valid
    formik.setFieldError('periodValue', '');
    return true;
  };
  
  return (
    <Drawer PaperProps={{ style: { width: '40%' } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
      <DrawerToolbar title="Filters" onClose={onClose} />
      <Container>

      <form noValidate onSubmit={formik.handleSubmit}>
      <Grid style={{paddingTop: '25px'}}>
      <Paper elevation={3} style={{ padding: 16 }}>
          <Typography variant="h6" gutterBottom>
            Location Filters
          </Typography>
              <Grid item xs={12}>
                  <Autocomplete
                        openOnFocus
                        multiple
                        options={[selectAllOption, ...(locations ? locations : [])]}
                        id="dealerId"
                        disabled={onlyNoInvoice()}
                        onChange={(_, values) => handleLocationChange(values)}
                        value={onlyNoInvoice()? locations : formik.values.locations}
                        getOptionLabel={(option) => option.locationName}
                        getOptionSelected={(option, value) => option.dealerId === value.dealerId}
                        renderOption={(option, { selected }) => (
                          <>
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={ option.dealerId === null ? allLocationsSelected :selected}
                            />
                            {option.locationName}
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="dealerId"
                            id="dealerId"
                            variant="outlined"
                            error={locationError()}
                            helperText={locationError() && "Please enter a location"}
                            margin="dense"
                            label="Location"
                            placeholder="Select Location(s)"
                          />
                        )}
                      />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      margin="dense"
                      label="Zip Code"
                      name="zipCodes"
                      onChange={formik.handleChange}
                      value={formik.values.zipCodes}
                      helperText={formik.errors.zipCodes}
                      error={!!(formik.touched.zipCodes && formik.errors.zipCodes)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      margin="dense"
                      type="text"
                      label="Distance from location(s) in miles"
                      name="zipCodesInRadius"
                      onChange={formik.handleChange}
                      value={formik.values.zipCodesInRadius}
                      helperText={formik.errors.zipCodesInRadius}
                      error={!!(formik.touched.zipCodesInRadius && formik.errors.zipCodesInRadius)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    openOnFocus
                    multiple
                    options={US_CANADA_PROVINCES}
                    id="states"
                    onChange={(_, values: any): void => handleStateChange(values)}
                    value={formik.values.states}
                    getOptionLabel={(option: Province): string => option.name}
                    getOptionSelected={(option: Province, value: Province): boolean => option.short === value.short}
                    renderInput={(params): React.ReactElement => (
                      <TextField
                        {...params}
                        name="states"
                        id="states"
                        variant="outlined"
                        error={!!(formik.touched.states && formik.errors.states)}
                        helperText={formik.errors.states}
                        margin="dense"
                        label="Select State"
                        placeholder="Select State"
                      />
                    )}
                  />
                </Grid>
          </Paper>
      </Grid>
      <Grid style={{paddingTop: '25px'}}>
            <Paper elevation={3} style={{ padding: 16 }}>
            <Typography variant="h6" gutterBottom>
                Invoice Filters
              </Typography>
              <Grid item xs={12}>
              <Autocomplete
                    openOnFocus
                    multiple
                    options={InvoiceOptions}
                    id="invoiceTypes"
                    onChange={(_, values) => handleInvoiceTypesChange(values)}
                    value={formik.values.invoiceTypes}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    renderOption={(option, { selected }) => (
                      <>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={option.value == 0 ? allInvoiceOptionsSelected : selected}
                        />
                        {option.label}
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="invoiceTypes"
                        id="invoiceTypes"
                        variant="outlined"
                        error={!!(formik.errors.invoiceTypes)}
                        helperText={formik.errors.invoiceTypes}
                        margin="dense"
                        label="Select Invoice Type"
                        placeholder="Select Invoice Type"
                      />
                    )}
                  />
                </Grid>
            <Paper style={{ backgroundColor: '#f5f5f5' }}>  
            <Box p={1} my={1}>
              <Box mb={1}>
                <Typography variant="h6">Invoice Time Frame</Typography>
              </Box>
              <TabContext value={InvoiceTimeFrameTabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} aria-label="Invoice Time Frame tabs">
                  <Tooltip title="Select the starting month and the number of months for your invoice period" arrow>
                    <Tab 
                      label="Duration" 
                      value="1" 
                      style={{ fontWeight: !formik.values.invoiceCalendarOption ? "bold" : "normal" }} 
                    />
                  </Tooltip>
                  <Tooltip title="Select custom start and end dates for your invoice" arrow>
                    <Tab 
                      label="Range" 
                      value="2" 
                      style={{ fontWeight:  formik.values.invoiceCalendarOption ? "bold" : "normal" }} 
                    />
                  </Tooltip>
                </TabList>
                </Box>

                {/* Duration Tab Panel */}
                <TabPanel value="1" style={{ paddingTop: 1}}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                    <TextField
                    fullWidth
                    style={{ backgroundColor: 'white' }}
                    type="text"
                    variant="outlined"
                    name="fromMonth"
                    label="From Month (0 Current)"
                    margin="dense"
                    size="small"
                    disabled={onlyNoInvoice()}
                    value={onlyNoInvoice()? '' : formik.values.fromMonth}
                    helperText={formik.errors.fromMonth}
                    error={!!(formik.touched.fromMonth && formik.errors.fromMonth)}
                    onChange={formik.handleChange}
                  />
                    </Grid>
                    <Grid item xs={6}>
                  <TextField
                    fullWidth
                    style={{ backgroundColor: 'white' }}
                    type="text"
                    variant="outlined"
                    name="periodValue"
                    // label="Invoice TimeFrame"
                    label="Period Month"
                    margin="dense"
                    size="small"
                    disabled={onlyNoInvoice()}
                    value={onlyNoInvoice()? '' : formik.values.periodValue}
                    helperText={formik.errors.periodValue}
                    error={!!(formik.touched.periodValue && formik.errors.periodValue)}
                    onChange={formik.handleChange}
                  />
                 </Grid> 
                </Grid>
                </TabPanel>

                {/* Range Tab Panel with Date Picker */}
                <TabPanel value="2" style={{ paddingTop: 1}}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        type="date"
                        variant="outlined"
                        name="startDate"
                        label="From (Start Date)"
                        error = {!!formik.errors.invoiceFrom}
                        helperText = {formik.errors.invoiceFrom}
                        margin="dense"
                        disabled={onlyNoInvoice()}
                        InputLabelProps={{ shrink: true }}  // To ensure label stays above the input
                        size="small"
                        inputProps={{
                          max: formik.values.invoiceTo && (new Date().toISOString().split('T')[0]),  // Disable future dates beyond invoiceTo
                        }}
                        value={onlyNoInvoice()? '' : formik.values.invoiceFrom}
                        onChange={(e) => {
                          if (validateDate(e.target.value)) {
                          formik.setFieldValue('invoiceFrom', e.target.value)}}  // Use setFieldValue to set the date value
                        }
                        />
                    </Grid>
                    <Grid item xs={6}>
                    <TextField
                          fullWidth
                          type="date"
                          variant="outlined"
                          name="invoiceTo"
                          label="To (End Date)"
                          margin="dense"
                          error = {!!formik.errors.invoiceTo}
                          helperText = {formik.errors.invoiceTo}
                          disabled={onlyNoInvoice()}
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          inputProps={{
                            min: formik.values.invoiceFrom || undefined,  // Disable future dates beyond invoiceTo
                            max: new Date().toISOString().split('T')[0],
                          }}
                          value={onlyNoInvoice()? '' : formik.values.invoiceTo || ''}  // Correct binding
                          onChange={(e) => {
                            if (validateDate(e.target.value)) {
                            formik.setFieldValue('invoiceTo', e.target.value)}}  // Use setFieldValue to set the date value
                          }
                        />
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
              <Grid container spacing={1}>      
                {/* <Grid item xs={6}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Time Period"
                    style={{ backgroundColor: 'white' }}
                    name="period"
                    margin="dense"
                    size="small"
                    value={formik.values.period}
                    helperText={formik.errors.period}
                    error={!!(formik.touched.period && !!formik.errors.period)}
                    onChange={formik.handleChange}>
                    {PeriodOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid> */}
              </Grid>
            </Box>
            </Paper>
          <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                margin="dense"
                label="Minimum Spent"
                name="amountSpent"
                disabled={onlyNoInvoice()}
                // onChange={formik.handleChange}
                onBlur={(e) => handleAmountSpent()}
                onChange={(e) => {
                  const val = e.target.value;
                  const integerPart = val.split('.')[0];  

                  if (/^\d*\.?\d*$/.test(val) && integerPart.length <= 12) {  
                    handleMinimumSpentState(e);
                }}}
                value={(onlyNoInvoice()) || (minimumSpent == 0) ? '' : minimumSpent}
                InputLabelProps={{
                  shrink: (onlyNoInvoice() == false && minimumSpent > 0) 
                }}
              // helperText={formik.errors.amountSpent}
              // error={!!(formik.touched.amountSpent && formik.errors.amountSpent)}
              />
            </Grid>
          </Paper>
      </Grid>
      <Grid style={{paddingTop: '25px'}}>
            <Paper elevation={3} style={{ padding: 16 }}>
            <Typography variant="h6" gutterBottom>
                Product Filters
              </Typography>
              <Grid item xs={12}>
      <Autocomplete
      openOnFocus
      multiple
      options={manufacturers}
      id="manufacturerCodes"
      onChange={(_, values) => handleManufacturerChange(values)}
      value={onlyNoInvoice()? [] : formik.values.manufacturerCodes}
      disableCloseOnSelect
      disabled={onlyNoInvoice()}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.code === value.code && option.manufacturerId === value.manufacturerId}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          name="manufacturerCodes"
          id="manufacturerCodes"
          variant="outlined"
          disabled={onlyNoInvoice()}
          error={!!(formik.touched.manufacturerCodes && formik.errors.manufacturerCodes)}
          helperText={formik.errors.manufacturerCodes}
          margin="dense"
          label="Select Product Line(s)"
          placeholder="Select Product Line(s)"
        />
      )}
    />
            </Grid>
            <Grid item xs={12}>
            <Autocomplete
            freeSolo={true}
            multiple
            id="tags-outlined"
            options={partNumberSuggestions}
            getOptionLabel={(option: any) => option??''}
            //Comment for reference: Below option don't work in freesolo mode
            //noOptionsText={'No suggestions'}
            value={onlyNoInvoice()? '' : formik.values.partNumber}
            onChange={handlePartNumberInputChange}
            disabled={onlyNoInvoice()}
            loading={partNumberLoading}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                margin="dense"
                {...params}
                label="Type Part/Model Number"
                variant="outlined"
                name="partNumber"
                error={!!(formik.touched.partNumber && formik.errors.partNumber)}
                helperText={formik.errors.partNumber}
                onChange={(e) => setPartNumberSearchQuery(e.target.value)}
              />
            )}
          />
          </Grid>
            </Paper>
    </Grid>
    <div style={{paddingTop: '15px'}}>
        <Paper elevation={3} style={{ padding: 20 }}>
            <Typography variant="h6" gutterBottom>
                Customer Filters
              </Typography>
              <Grid container spacing={1}>
              <Grid item xs={12}>
              <Autocomplete
                openOnFocus
                multiple
                options={customerCategories}
                id="customerCategoryIds"
                onChange={(_, values: any): void => handleCustomerCategoryChange(values)}
                value={formik.values.customerCategoryIds}
                getOptionLabel={(option: CustomerCategory): string => option.name}
                getOptionSelected={(option: CustomerCategory, value: CustomerCategory): boolean =>
                  option.customerCategoryId === value.customerCategoryId
                }
                renderInput={(params): React.ReactElement => (
                  <TextField
                    {...params}
                    name="customerCategoryIds"
                    id="customerCategoryIds"
                    variant="outlined"
                    error={!!(formik.touched.customerCategoryIds && formik.errors.customerCategoryIds)}
                    helperText={formik.errors.customerCategoryIds}
                    margin="dense"
                    label="Select Customer Category"
                    placeholder="Select category"
                  />
                )}
              />
              </Grid>
              <Grid item xs={12}>
              {phoneArray && phoneArray.length > 0 && (
            <Box border={1} borderRadius={4} borderColor="#21212145">
              {phoneArray.map((num: any, index: number) =>
                  <Chip
                    style={{ margin: '1px', width:'auto', maxWidth:'100%' }}
                    size="small"
                    label={`(${num.slice(0, 3)}${') ' + num.slice(3, 6)}${'-' + num.slice(6)}`}
                    onDelete={() => {
                      handlePhoneTagDelete(num, index);
                    }}
                  />
              )}
            </Box>
          )}
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            margin="dense"
            label="Add Phone Number"
            name="phoneNumber"
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, '');
              if (value.length > 10) e.preventDefault(); 
              formik.setFieldValue('phoneNumber', value);
              if (value.length === 10) {
              // Prevent duplicate phone numbers
              if (!phoneArray.includes(value)) {
                setPhoneArray([...phoneArray, value]);
              } else { 
                showSnackBar({ type: 'info', message: 'Duplicate number removed. Please enter a unique number.' });
              }
                formik.setFieldValue('phoneNumber', '');
              }
            }}
            onBlur={() => {
              const number = formik.values.phoneNumber.replace(/[^0-9]/g, '');
              validatePhoneNumber(number)
            }}
            value={FormatPhoneNumber(formik.values.phoneNumber)}
            helperText={formik.errors.phoneNumber}
            error={!!(formik.errors.phoneNumber)}
          />
          </Grid>
          <Grid item xs={6}>
            <TextField
                    className={classes.textFieldPadding}
                    select
                    name="salesStageId"
                    fullWidth
                    margin='dense'
                    // value={dealContext.defaultPipelineId && formik.values.pipelineId == 0 ? dealContext.defaultPipelineId : formik.values.pipelineId}
                    value={formik.values.salesStageId}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          className: classes.muiPopoverPaper,
                        },
                      },
                    }}
                    InputLabelProps={{
                      shrink: formik.values.salesStageId !== null,
                    }}
                    onChange={(event: any) => {
                      if (event.target.value > 0) {
                        formik.setFieldValue('salesStageId', event.target.value)
                      } else {
                        formik.setFieldValue('salesStageId', null)
                      }                   
                      }}
                    label="Sales Stage"
                    variant="outlined"
                    size="small">
                    {(salesStages?.map((stage, index) => {
                      return (
                        <MenuItem key={stage.id} value={stage.id}>
                          {stage.salesStage}
                        </MenuItem>
                      );
                    }))}
                  </TextField>
            </Grid>
              <Grid item xs={6}>

              <TextField
                fullWidth
                size="small"
                value={formik.values.customerTag}
                variant="outlined"
                name="customerTag"
                margin="dense"
                label="Customer Tag"
                onClick={() => { setPopoverState(true); }}
                onChange={(e: any) => {
                  formik.setFieldValue("customerTag", e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ paddingRight: 0 }}>
                      {formik.values.customerTag && (
                        <IconButton  style={{ padding: 0 }} onClick={(e) => { 
                          e.stopPropagation();
                          formik.setFieldValue("customerTag", "");
                          setPopoverState(false); 
                        }}>
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton  style={{ padding: 0 }} onClick={() => { setPopoverState(true); }}>
                        <ArrowDropDownIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

            </Grid>
            <Grid item xs={12}>
            <Popover 
            open = {popoverState}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            onClose={() => {
              setPopoverState(false)
            }}>
            <Box p={2} style={{ width: '300px' }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search tags"
              value={searchInput}
              onChange={handleSearchChange}
              size="small"
              style={{ marginBottom: '10px' }}
            />

              <List dense style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {staticCustomerTags.map((title: any, index: number) => (
                  <React.Fragment key={index}>
                    {filteredTags(title).length > 0 && (
                      <>
                        <Typography
                          variant="subtitle2"
                          style={{ color: '#333', fontWeight: 'bold', marginBottom: '5px' }}
                        >
                          {title.title}
                        </Typography>
                        <Divider />
                      </>
                    )}
                    {filteredTags(title).map((option: any, index: number) => (
                      <MenuItem
                      key={index}
                        onClick={() => {
                          if (title !== 'Miscellaneous') {
                            formik.setFieldValue('customerTag', option.tag)
                          } else {
                            formik.setFieldValue('customerTag', option)
                          }
                          setPopoverState(false)
                           
                        }}
                      >
                        <ListItemIcon style={{ minWidth: '24px' }}>
                          <FiberManualRecordIcon style={{ fontSize: '12px' }} />
                        </ListItemIcon>
                        <ListItemText primary={ <Typography
                                          variant="body1"
                                          style={{
                                            wordBreak: 'break-word',
                                            overflowWrap: 'break-word',
                                            whiteSpace: 'normal'
                                          }}
                                        >
                                          {title !== 'Miscellaneous' ? option.tag : option}
                                        </Typography>} 
                      
                       />
                      </MenuItem>
                    ))}
                  </React.Fragment>
                ))}
              </List>
              </Box>
              </Popover>
          </Grid>
          </Grid>
        </Paper>

        </div>

        <div style={{paddingTop: '15px', paddingBottom: '15px'}}>
        <Paper elevation={3} style={{ padding: 20 }}>
            <Typography variant="h6" gutterBottom>
                Cross Sell Time Frame
              </Typography>
              <Paper style={{ backgroundColor: '#f5f5f5' }}>
            <Box p={1} my={1}>
              {/* <Box mb={1}>
                <Typography variant="h6">Cross Sell Time Frame</Typography>
              </Box> */}
              <TabContext value={crossSellTabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleCrossSellTabChange} aria-label="Invoice Time Frame tabs">
                <Tooltip title="Select the starting month and the number of months for your invoice period" arrow>
                  <Tab 
                    label="Duration" 
                    value="1" 
                    style={{ fontWeight: !formik.values.noSaleCalendarOption ? "bold" : "normal" }} 
                  />
                </Tooltip>
                <Tooltip title="Select custom start and end dates for your invoice" arrow>
                  <Tab 
                    label="Range" 
                    value="2" 
                    style={{ fontWeight:  formik.values.noSaleCalendarOption ? "bold" : "normal" }} 
                  />
                </Tooltip>
              </TabList>
                </Box>
              <TabPanel value="1" style={{ paddingTop: 1}}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Cross Sell"
                    name="noSaleOption"
                    size="small"
                    type="text"
                    style={{ backgroundColor: 'white' }}
                    value={onlyNoInvoice()? null : formik.values.noSaleOption}
                    disabled={onlyNoInvoice()}
                    error={!!(formik.touched.noSaleOption && formik.errors.noSaleOption)}
                    helperText={formik.errors.noSaleOption}
                    onChange={formik.handleChange}
                    margin="dense">
                    {NoSaleOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-evenly' }}>
                  in
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Time Period"
                    name="noSaleOptionValue"
                    size="small"
                    type="text"
                    margin="dense"
                    disabled={onlyNoInvoice()}
                    style={{ backgroundColor: 'white' }}
                    value={onlyNoInvoice()? '' : formik.values.noSaleOptionValue}
                    onChange={formik.handleChange}
                    error={!!(formik.touched.noSaleOptionValue && formik.errors.noSaleOptionValue)}
                    helperText={formik.errors.noSaleOptionValue}
                  />
                </Grid>
              </Grid>
              </TabPanel>
              <TabPanel value="2" style={{ paddingTop: 1}}>
              <Grid container spacing={2}>
            {/* Cross Sell Text Field (Full Width at the Top) */}
            <Grid item xs={12}>
              <TextField
                select
                variant="outlined"
                label="Cross Sell"
                name="noSaleOption"
                size="small"
                type="text"
                style={{ backgroundColor: 'white', width: '97%', marginBottom: '3px' }}
                value={onlyNoInvoice() ? null : formik.values.noSaleOption}
                disabled={onlyNoInvoice()}
                error={!!(formik.touched.noSaleOption && formik.errors.noSaleOption)}
                onChange={formik.handleChange}
                margin="normal"  // Provides consistent spacing
              >
                {NoSaleOptions.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </TextField>
              {formik.errors.noSaleOption && (
                <Typography
                  variant="body2"
                  style={{
                    color: '#d32f2f', 
                    marginLeft: '14px',
                    fontSize: '0.70rem',
                  }}>
                  {formik.errors.noSaleOption}
                </Typography>
              )}
            </Grid>

            {/* Date Fields Side by Side */}
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="date"
                  variant="outlined"
                  name="noSaleFrom"
                  label="From (Start Date)"
                  margin="normal"
                  InputLabelProps={{ shrink: true }}  // Keeps label above input
                  disabled={onlyNoInvoice()}
                  size="small"
                  error = {!!(formik.errors.noSaleFrom)}
                  helperText = {formik.errors.noSaleFrom}
                  inputProps={{
                    max: formik.values.noSaleTo && new Date().toISOString().split('T')[0], // Max is either 'noSaleTo' or today
                  }}
                  value={onlyNoInvoice() ? '' : formik.values.noSaleFrom || ''}
                  onChange={(e) => {
                    if (validateDate(e.target.value)) {
                    formik.setFieldValue('noSaleFrom', e.target.value)}
                  }
                }
                />
              </Grid>
              
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="date"
                  variant="outlined"
                  name="noSaleTo"
                  label="To (End Date)"
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  error = {crossSellError && !!(formik.errors.noSaleTo)}
                  helperText = {crossSellError && (formik.errors.noSaleTo)}
                  disabled={onlyNoInvoice()}
                  inputProps={{
                    min: formik.values.noSaleFrom || undefined,
                    max: new Date().toISOString().split('T')[0]
                  }}
                  value={onlyNoInvoice() ? '' : formik.values.noSaleTo || ''}
                  onChange={(e) => {
                    if (validateDate(e.target.value)) {
                    setCrossSellError(true)
                    formik.setFieldValue('noSaleTo', e.target.value)}}
                  } 
                />
              </Grid>
            </Grid>
          </Grid>


                </TabPanel>
              </TabContext>
            </Box>
          </Paper>
        </Paper>
        </div>

          
          <Grid container spacing={1}>
          </Grid>
          <Grid container spacing={1}>
          </Grid>
          {/* {phoneArray && phoneArray.length > 0 && (
            <Box border={1} borderRadius={4} borderColor="#21212145">
              {phoneArray.map((num: any, index: number) =>
                  <Chip
                    style={{ margin: '1px', width:'auto', maxWidth:'100%' }}
                    size="small"
                    label={`(${num.slice(0, 3)}${') ' + num.slice(3, 6)}${'-' + num.slice(6)}`}
                    onDelete={() => {
                      handlePhoneTagDelete(num, index);
                    }}
                  />
              )}
            </Box>
          )} */}

          <Grid container spacing={1}>

          </Grid>





          {/* <TextField
            fullWidth
            variant="outlined"
            size="small"
            margin="dense"
            label="Model Number1"
            name="modelNumber"
            onChange={formik.handleChange}
            value={formik.values.modelNumber}
            helperText={formik.errors.modelNumber}
            error={!!(formik.touched.modelNumber && formik.errors.modelNumber)}
          /> */}
          <div style={{ position: "absolute", right: "85px", top: "2px" }}>
            <Box mt={1} display="flex" flexDirection="row-reverse">
              <Button type="submit" variant="contained" color="primary">
                Save Changes
              </Button>
            </Box>
          </div>
          {/* <Button size="medium" onClick={FileUploadClick}
            classes={{
              root: classes.button1,
              // label: classes.buttonValidatePhoneLabel, 
            }}>
            Upload File
          </Button> */}
          {uploadText != '' && (
            <Button size="medium" onClick={ClearFileUploadClick}
              classes={{
                root: classes.button1,
                // label: classes.buttonValidatePhoneLabel, 
              }}>
              Clear File
            </Button>
          )}

          <input id='inputFileUpload' type="file" name="file" onChange={inputFileUploadOnchange} style={{ display: "none" }} />
        </form>
        {uploadText != '' && (

          <div style={{ whiteSpace: "pre-wrap", border: "1px solid gray", display: "inline-block", padding: "5px", maxHeight: "100px", overflow: "scroll", fontSize: "small" }}>{uploadText}</div>
        )}
      </Container>
    </Drawer >
  );
}
