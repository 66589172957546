import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  makeStyles, Paper,
  TextField,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@mui/icons-material/Close';
import MarkAsUnread from '@mui/icons-material/Markunread';
import ArchiveIcon from '@mui/icons-material/Archive';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useAppDispatch, useAppSelector } from 'app';
import clsx from 'clsx';
import { PageHeader } from 'common/components/layout';
import { useRouter } from 'common/hooks';
import { Notification } from 'common/models/notification';
import CustomerRootPageDrawer from 'components/customer/components/CustomerDrawer/CustomerRootPageDrawer';
import { fetchSelectedCustomer, setUpdatedCustomerName } from 'components/customer/components/Customers/customersSlice';
import DepartmentFilterContext from 'components/layouts/DashboardLayout/department-filter-context';
import { MessengerContext } from 'components/layouts/DashboardLayout/messenger-context';
import { SignalRContext } from 'components/layouts/DashboardLayout/signalr-context';
import { showSnackbar } from 'components/root-snackbar';
import DepartmentAPI from 'components/setting/api/department-api';
import UserAPI from 'components/setting/api/user-api';
import Department from 'components/setting/models/department';
import User from 'components/setting/models/user';
import { uniqBy } from 'lodash';
import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import messagingApi from '../api/messaging-api';
import { Conversation } from '../models/conversation';
import MessageReceived from '../models/MessageReceived';
import { NewConversationType } from '../models/new-conversation-type';
import ConversationListPanel from './conversation-list-panel';
import ConversationPanel, { ConversationPanelRef } from './conversation-panel/conversation-panel';
import NewConversationPanel from './new-conversation-panel';
import { SplitPanel } from './split-panel';
import Swal from 'sweetalert2';
import DealerAPI from 'components/authentication/api/dealer-api';
import CustomerAPI, { CustomerCategory } from 'components/customer/api/CustomerAPI';
import { useLocation } from 'react-router-dom';
import { ConversationType } from 'common/utils/shared/shared-constants';
import { ErrorMessages } from 'common/utils/shared/shared-messages';
import { ArchiveRequest } from '../models/request/archiveRequest';


const useMessengerStyles = makeStyles((theme: Theme): any => ({
  container: {
    flexGrow: 1,
    height: `calc(100% - ${theme.navBar.height + theme.spacing(2)})`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  containerShift: {
    width: `calc(100% - ${550}px)`,
    marginRight: 550,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  root: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    height: '80vh',
    minHeight: '80vh',
  },
  messages: {
    width: '70%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  messageFieldBox: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    textAlign: 'right',
    display: 'flex',
    alignItems: 'flex-end',
    overflow: 'hidden',
    height: '100px',
  },
  updateConversationPanel: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 1,
    height: '-webkit-fill-available',
  },
  markBtn: {
    borderRadius: 8,
    minHeight: '100px',
    minWidth: '150px',
    marginLeft: '5%',
  }
}));

//let sortingOrder = 'desc';
let timer: any;
let isLoading = 0;
let conversationsList: Conversation[] = [];
export function Messenger({ hideNavigations = false, defaultSearch = "", phoneNumber = "", CloseMe = undefined }: { hideNavigations?: boolean, defaultSearch?: string, phoneNumber?: string, CloseMe?(): void }): ReactElement {
  const classes: any = useMessengerStyles();
  const signalRConnection = useContext(SignalRContext);
  const { selectedDepartmentIds } = useContext(DepartmentFilterContext);
  const [customerId, setCustomerId] = useState(0);
  const [selectedConversation, setSelectedConversation] = useState<Conversation | undefined>();
  const [showStartNewConversation, setStartNewConversation] = useState(false);
  const [pageNumber, setPageNumber] = useState(-1);
  const [searchText, setSearchText] = useState(defaultSearch);
  const pageSize = 30;
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [departmentLoading, setDepartmentLoading] = useState(false);

  const [isConversationRemaining, setConversationRemaining] = useState(true);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const defaultDealerId = useAppSelector((state) => state.user.dealerId);
  const [createConversationLoading, setCreateConversationLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { fetchUnreadConversationsCount } = useContext(MessengerContext);
  const [selectedConversationIds, setConversationList] = useState<number[]>([]);
  const [conversationTypes, setConversationTypes] = React.useState<string>(ConversationType.All.Value);
  const [conversationFilters, setConversationFilters] = React.useState<string[]>([]);
  const [selectedMessageSid, setSelectedMessageSid] = useState("");
  const [reAssignPanel, setReAssignPanel] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc')

  const childRef = useRef<ConversationPanelRef>(null);
  const router = useRouter();
  function setUniqConversations(conversList: Conversation[]): void {
    if (!conversList || conversList.length == 0) {
      const conversationId = query.get("conversationId");
      if (conversationId) {
        return;
      }
    }
    const uniqConversations = uniqBy(conversList, (conversation) => conversation.id);
    uniqConversations.sort((a: Conversation, b: Conversation) => {
      if (a.latestDateTime != null && b.latestDateTime != null) {
        return new Date(a.latestDateTime).getTime() - new Date(b.latestDateTime).getTime();
      }
      return 0;
    });
    setConversations(uniqConversations);
    if (defaultSearch.includes('CrmCustomerId:')) conversationsList = uniqConversations;
  }

  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const dealerId = useAppSelector((state) => state.user.dealerId);

  const updatedCustomerName = useAppSelector((state) => state.customers.updatedCustomerName);
  const dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);

  const [users, setUsers] = useState<User[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);

  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [selectedUser, setSelectedUser] = useState('0');

  const [defaultCategory, setDefaultCategory] = useState<string>('');
  const [categories, setCategories] = useState<CustomerCategory[]>([]);

  const [hideOtherNavigation, setHideOtherNavigation] = useState(hideNavigations);

  const location = useLocation();
  const query = new URLSearchParams(location.search);


  const dealerdefaultCategory = async () => {
    const resp = await DealerAPI.getDealerSettings(dmsDealerIdCrm);
    const catCheck = resp.dealerLocationSettings.length > 0 &&
      (resp.dealerLocationSettings[0].defaultCustomerCategoryId === null || resp.dealerLocationSettings[0].defaultCustomerCategoryId === '');
    if (resp && (resp.dealerLocationSettings.length <= 0 || catCheck)) {
      const categories = await CustomerAPI.getCategories(dealerId);
      setCategories(categories);
    } else {
      setDefaultCategory(resp.dealerLocationSettings[0]?.defaultCustomerCategoryId);
    }
  };
  useEffect(() => {
    if (defaultCategory === '' || defaultCategory === undefined) {
      dealerdefaultCategory();
    }
  }, []);


  useEffect(() => {
    const conversationId = query.get("conversationId") ?? 0;
    const smsSid = query.get("SmsSid") ?? '';
    if (conversationId)
      messagingApi.getConversation(Number(conversationId)).then((x: Conversation) => {
        let conversation = x;
        conversation.latestMessageSid = smsSid;
        setSelectedConversation(x);
        if (smsSid)
          setSelectedMessageSid(smsSid);
      });
  }, [selectedDepartmentIds]);

  const loggedInUserId = useAppSelector((state) => state.user.crmUserId);
  const handleSearchChecked = (value: boolean) => {
    setPageNumber(0);
  }

  const handleConversationCheckbox = async (conversationId: number, status: boolean) => {
    if (status === true) {
      setConversationList([...selectedConversationIds, conversationId]);
    }
    else {
      let tempConvo = [...selectedConversationIds];
      tempConvo = tempConvo.filter(x => x !== conversationId);
      setConversationList(tempConvo);
    }
  }

  const handleSortedConversations = async (sortOrder: string) => {
    setSortOrder(sortOrder);
  }

  const fetchUsersApi = async (departmentId: number) => {
    setUserLoading(true);
    const users = await UserAPI.getUsersByDepartment(departmentId);
    setUsers(users);
    setUserLoading(false);
  }

  const fetchDepartmentsApi = async (crmUserId: string) => {
    try {
      setDepartmentLoading(true);
      if (!crmUserId || crmUserId === "0") {
        const response = await DepartmentAPI.getDepartments(0, dmsDealerIdCrm);
        setDepartments(response);
      } else {
        const response = await DepartmentAPI.getUserDepartmentSetting(dmsDealerIdCrm, crmUserId);
        let d: Department[] = response.userAssignedDepartments.map((x: any) => ({ departmentId: x.departmentId, departmentName: x.departmentName, userCount: 0, users: undefined, dealerLocationId: 0, dealerLocationName: x.dealerLocationName, departmentType: "", phoneNumber: "", deleteConfirmation: false, isTollFreeNumber: false }));
        setDepartments(d);
      }
      setDepartmentLoading(false);
    } catch (error) {
      setDepartmentLoading(false);
      console.error(error);
    }
  }

  React.useEffect(() => {
    if (reAssignPanel) {
      fetchDepartmentsApi(selectedUser);
    }
  }, [selectedUser, reAssignPanel]);

  React.useEffect(() => {
    if (reAssignPanel) {
      fetchUsersApi(selectedDepartment);
    }
  }, [selectedDepartment, reAssignPanel]);

  const fetchConversationsApi = async (conversationType: string, conversationFilters: string[], load: boolean = false, loader: boolean = true, refreshCurrentConv: boolean = false) => {
    try {
      if (pageNumber < 0) return;
      loader && setLoading(true);
      const getConversationsResponse = await messagingApi.getConversations(searchText, pageNumber, pageSize, conversationType, conversationFilters, sortOrder);
      let newConversations = []
      if (pageNumber === 0) {
        newConversations = [...getConversationsResponse.conversations];
      } else {
        newConversations = [...conversations, ...getConversationsResponse.conversations];
      }

      if (defaultSearch != '') {
        if (newConversations.length > 0) {
          setSelectedConversation(newConversations[0]);
        }
        if (newConversations.length == 0) {
          handleStartConversation();
        }
      }

      if (searchText || load) {
        setConversations(newConversations);
        if (defaultSearch.includes('CrmCustomerId:')) conversationsList = newConversations;
      }
      else {
        setUniqConversations(newConversations);
      }

      if (refreshCurrentConv) {
        const selectedConv = getConversationsResponse.conversations.find((x) => x.id === selectedConversation?.id);
        if (selectedConv) handleOnConversationSelected(selectedConv);
      }

      setConversationRemaining(getConversationsResponse.isRemaining);
      loader && setLoading(false);
      isLoading = 0;
    } catch (error) {
      loader && setLoading(false);
      isLoading = 0;
      console.error(error);
    }
  }

  const handleConvTypeChange = async (type: string) => {
    setPageNumber(0);
    // setConversations([]);
    setConversationTypes(type);
    setSelectedConversation(undefined);
    // await fetchConversationsApi(type, conversationFilters);
  }
  const handleConvFiltersChange = async (filters: string[]) => {
    setPageNumber(0);
    // setConversations([]);
    setConversationFilters(filters);
    setSelectedConversation(undefined);
    // await fetchConversationsApi(conversationTypes, filters);
  }

  async function createNewConversation(type: NewConversationType, data: any, departmentId: number, phoneNumberId: number): Promise<void> {
    let selectedCustomerId = 0;
    let cellPhone = data.phoneNumber || '';

    if (type === NewConversationType.NewCustomerConversation && typeof data === 'string') {
      selectedCustomerId = 0;
      cellPhone = data;
    } else if (type === NewConversationType.CustomerConversation) {
      selectedCustomerId = data.id;
    }
    try {
      setCreateConversationLoading(true);
      const conversation = await messagingApi.createConversation(cellPhone, departmentId, data.name, phoneNumberId);
      if (!conversations.find((x) => x.id === conversation.id)) {
        setUniqConversations([conversation, ...conversations]);
      }
      setCreateConversationLoading(false);
      setStartNewConversation(false);
      setSelectedConversation(conversation);
    } catch (error: any) {
      console.log(error);
      setCreateConversationLoading(false);
      setStartNewConversation(false);
      if (error.response) {
        dispatch(
          showSnackbar({
            type: 'error',
            message: error.response.data.message,
          }),
        );
      }
    }
  }


  function handleOnContinue(conversation: Conversation): void {
    setConversations([conversation, ...conversations.filter((c) => c.id !== conversation.id)]);
    if (defaultSearch.includes('CrmCustomerId:')) conversationsList = [conversation, ...conversations.filter((c) => c.id !== conversation.id)];
    handleOnConversationSelected(conversation);
  }

  async function handleOnConversationSelected(conversation: Conversation): Promise<void> {
    setStartNewConversation(false);
    setSelectedConversation(conversation);
    if (conversation.latestMessageSid != undefined) {
      await setSelectedMessageSid(conversation.latestMessageSid);
    }
    setCustomerId(0);
    if (childRef && childRef.current) {
      await childRef.current.fetchConversationMessage();
    }
  }

  async function handleMarkConversationRead(conversation: Conversation): Promise<void> {
    //Set unreadMessageCount = 0 for the selected conversation
    const selectedConv = selectedConversation;
    if (selectedConv) selectedConv.unreadMessageCount = 0;
    setSelectedConversation(selectedConv);

    let isWindowActive = true;
    if (window) {
      isWindowActive = window.document.hidden;
    }
    if (!isWindowActive) {
      try {
        const updateConversation = await messagingApi.markConversationRead(conversation.id);
        if (updateConversation.latestDateTime) {
          updateConversation.latestDateTime = updateConversation.latestDateTime.replace("Z", "");
        }
        const indexOfConversation = conversations.map((x) => x.id).indexOf(updateConversation.id);
        if (indexOfConversation !== -1) {
          const newConversations = [...conversations];
          newConversations[indexOfConversation] = updateConversation;
          setUniqConversations(newConversations);
        }
        // Commented this call, as this senario is already handled in the DashboardLayout.tsx file
        // fetchUnreadConversationsCount();
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function RefreshMessenger(callAPIs = true): Promise<void> {
    setSelectedConversation(undefined);
    setConversationList([]);
    if (callAPIs) {
      await fetchConversationsApi(conversationTypes, conversationFilters);
      fetchUnreadConversationsCount();
    }
  }

  async function handleMarkConversationUnRead(conversationIds: number[]): Promise<void> {
    try {
      const response = await messagingApi.markConversationUnRead(conversationIds);
      if (response) {
        RefreshMessenger(false);
        dispatch(
          showSnackbar({
            type: 'success',
            message: `Selected conversation${conversationIds.length == 1 ? '' : 's'} updated`,
          }),
        );
      } else {
        dispatch(
          showSnackbar({
            type: 'error',
            message: "Unable to update selected conversations",
          }),
        );
      }

    } catch (error) {
      console.error(error);
      dispatch(
        showSnackbar({
          type: 'error',
          message: "Unable to update selected conversations",
        }),
      );
    }
  }

  async function handleMarkConversationArchive(archiveRequest:ArchiveRequest): Promise<void> {
    try {
      const archiveResponse = await messagingApi.markConversationArchive(archiveRequest);
      if (archiveResponse.isSuccess) {
        RefreshMessenger()
        dispatch(
          showSnackbar({
            type: 'success',
            message: `Your conversation '${archiveRequest.Title}' has been archived successfully.`,
          }),
        );
      }
      else {
        dispatch(
          showSnackbar({
            type: 'error',
            message:'Error occured while merging. Please see details:\n\n'+archiveResponse.error,
          }),
        );
      }

    } catch (error) {
      console.error(error);
      dispatch(
        showSnackbar({
          type: 'error',
          message: "Unable to archive the conversation.",
        }),
      );
    }
  }

  async function handleCustomerUpdated(): Promise<void> {
    if (selectedConversation) {
      try {
        const newConversation = await messagingApi.getConversation(selectedConversation.id);
        setSelectedConversation(newConversation);
        // const newConversationsList = conversations.map((conversation) => {
        //   if (conversation.id !== selectedConversation.id) return conversation;
        //   return newConversation;
        // });
        // setUniqConversations(newConversationsList);
        await fetchConversationsApi(conversationTypes, conversationFilters);
        setCustomerId(0);
      } catch (error) {
        console.error(error);
        setCustomerId(0);
      }
    }
  }

  function handleOpenCustomerEditDrawer(selectedCustomerId: number): void {
    setCustomerId(selectedCustomerId);
  }
  function handleMarkSingleConversationUnRead(conversationId: number): void {
    let conversationIds = [];
    conversationIds.push(conversationId);
    handleMarkConversationUnRead(conversationIds);
  }

  function handleMarkSingleConversationArchive(archiveRequest:ArchiveRequest): void {
    handleMarkConversationArchive(archiveRequest);
  }

  function handleAssignSingleConversation(conversationId: number): void {
    let conversationIds = [];
    conversationIds.push(conversationId);
    setReAssignPanel(true);
    setConversationList(conversationIds);
  }
  function handleSearchConversation(value: string): void {
    if (loading === false) {
      setSearchText(value);
    }
  }

  function handleLatestMessageReceived(conversation: Conversation): void {    
    if (selectedConversation) {
      const updateSelectedConversation: Conversation = {
        ...selectedConversation,
        assignedUsername: conversation.assignedUsername,
        latestDateTime: conversation.latestDateTime,
        latestMessage: conversation.latestMessage,
      };
      const conversList =
        defaultSearch.includes('CrmCustomerId:') && conversationsList?.length > 0 ? conversationsList : conversations;
      const newConversationsList = conversList.map((conversation) => {
        if (conversation.id !== selectedConversation.id) return conversation;
        return updateSelectedConversation;
      });
      setUniqConversations(newConversationsList);
      setSelectedConversation(updateSelectedConversation);
    }
  }

  function handleFetchConversationNextPage(): void {
    if (isLoading == 0 && loading === false && isConversationRemaining) {
      isLoading = 1;
      setPageNumber(pageNumber + 1);
    }
  }
  
  function IsUnreadConversation(conversationIds:number[]):boolean
  {
    return  conversations.some(x=> conversationIds.includes(x.id) && x.unreadMessageCount != 0)
  }

  function handleMarkConvUnRead(): void {
    handleMarkConversationUnRead(selectedConversationIds);
  }

  async function handleStartConversation(): Promise<void> {
    const response = await DepartmentAPI.getUserDepartmentSetting(dmsDealerIdCrm, loggedInUserId);
    let d: Department[] = response.userSelectedDepartments.map((x: any) => ({ departmentId: x.departmentId, departmentName: x.departmentName, userCount: 0, users: undefined, dealerLocationId: 0, dealerLocationName: x.dealerLocationName, departmentType: "", phoneNumber: "", deleteConfirmation: false, isTollFreeNumber: false }));
    if (!d || d.length === 0) {
      dispatch(
        showSnackbar({
          type: 'error',
          message: "Please select a department first",
        }),
      );
      return;
    }
    setStartNewConversation(true);
    setSelectedConversation(undefined);
  }
  function handleClose(): void {
    setConversationList([]);
    setReAssignPanel(false);
    ResetAutoComplete();
  }

  const fetchConversations = React.useCallback(async (pageNo?: number): Promise<void> => {
    await fetchConversationsApi(conversationTypes, conversationFilters);
  },
    [searchText, pageNumber, conversationTypes, conversationFilters, sortOrder],
  );
  
  const canAssignConversations = (): boolean =>
    {
      let userDepartments = autoCompleteDepartmentEntries ;
      let selectedConverstions = conversations.filter(x=> selectedConversationIds.includes(x.id) );
      let conDeptNotExist = selectedConverstions.filter(x=> !userDepartments.some(y => y.id == (x.assignedDepartmentId ?? x.departmentId)));
      
      if(conDeptNotExist.length == 0 )
        return true;

      const formattedMessage = `Assignment failed! The target user doesn't have access to one or more selected departments.`;

      dispatch(
        showSnackbar({
          type: 'error',
          message: formattedMessage,
        }),
      );

      return false;
    }
    
  const assignConversation = async () => {
    if (selectedDepartment === 0 && selectedUser === "0") {
      dispatch(
        showSnackbar({
          type: 'info',
          message: "Please select department or user",
        }),
      )
      return;
    }

    if(selectedDepartment === 0 && !canAssignConversations() )
      return

    let resp = await messagingApi.assignConversation(selectedConversationIds, selectedDepartment, selectedUser);
    if (resp) {
      await fetchConversationsApi(conversationTypes, conversationFilters);
      fetchUnreadConversationsCount();
      setConversationList([]);
      setSelectedConversation(undefined);
      setReAssignPanel(false);
      dispatch(
        showSnackbar({
          type: 'success',
          message: `successfully assigned selected conversation${selectedConversationIds.length == 1 ? '' : 's'}`,
        }),
      )

    } else {
      dispatch(
        showSnackbar({
          type: 'error',
          message: "unable to assign conversations",
        }),
      )
    }
    ResetAutoComplete();
  }


  React.useEffect(() => {
    setConversations([]);
    conversationsList = [];
    setStartNewConversation(false);
    setPageNumber(0);
    setSelectedConversation(undefined);
    fetchConversations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartmentIds]);


  const unAssignConversation = async () => {
    let resp = await messagingApi.unAssignConversation(selectedConversationIds, 0, null);
    if (resp && resp.toLowerCase() === "ok") {
      await fetchConversationsApi(conversationTypes, conversationFilters);
      setConversationList([]);
      fetchUnreadConversationsCount()
      setSelectedConversation(undefined);
      setReAssignPanel(false);
      dispatch(
        showSnackbar({
          type: 'success',
          message: `successfully un-assigned selected conversation${selectedConversationIds.length == 1 ? '' : 's'}`,
        }),
      )
    } else {
      dispatch(
        showSnackbar({
          type: 'error',
          message: "unable to un-assign conversations",
        }),
      )
    }
    ResetAutoComplete();
  }

  const onMessageSentUpdateConversations = async (conversationId: number) => {
    let conversation = conversations?.filter((x: Conversation) => x.id === conversationId)[0];
    if (conversation?.assignedUsername === '' || conversation?.assignedUsername === null) {
      await fetchConversationsApi(conversationTypes, conversationFilters, true);
    }
  }
  const onMessageRecievedUpdateConversations = async (conversationId: number) => { 
    await fetchConversationsApi(conversationTypes, conversationFilters, true);
    let conversation = conversations?.filter((x: Conversation) => x.id === conversationId)[0];    
    handleMarkConversationRead(conversation);
  }

  React.useEffect(() => {
    //Changes for showing messenger only for a customer
    const hideNavigationFromQuery = query.get('hideOtherNavigation');
    if (hideNavigationFromQuery) setHideOtherNavigation(hideNavigationFromQuery === 'true' ? true : false);
  }, []);

  React.useEffect(() => {
    fetchConversations();
  }, [fetchConversations, pageNumber, searchText, sortOrder]);

  React.useEffect(() => {
    if (selectedConversationIds.length === 0) {
      setReAssignPanel(false);
    }
  }, [selectedConversationIds]);

  React.useEffect(() => {
    if (updatedCustomerName) {
      fetchConversationsApi(conversationTypes, conversationFilters, false, true, true);
      dispatch(setUpdatedCustomerName(null));
    }
  }, [updatedCustomerName]);

  const [autoCompleteUserValue, setAutoCompleteUserValue] = useState({ label: "Select User", id: 0 });
  const [autoCompleteDepartmentValue, setAutoCompleteDepartmentValue] = useState({ label: "Select Department", id: 0, dealerLocation: "" });
  const autoCompleteEntries = users.map((u: any) => ({ label: u.username, id: u.crmUserId }));

  const autoCompleteDepartmentEntries = departments ? departments.map((u: Department) => ({ label: u.departmentName, id: u.departmentId, dealerLocation: u.dealerLocationName })) : [];

  useEffect(() => {
    function updateConversationList(conversation: Conversation): void {
      
      const indexOfConversation = conversations.map((x) => x.id).indexOf(conversation.id);
      if (conversation.latestDateTime) {
        conversation.latestDateTime = conversation.latestDateTime.replace("Z", "");
      }
      if (indexOfConversation !== -1) {
        const newConversations = conversations;
        newConversations[indexOfConversation] = conversation;
        setUniqConversations(newConversations);
      } else {
        const newConversations = [conversation, ...conversations];
        setUniqConversations(newConversations);
      }  
    }

    function deleteConversation(conversation: Conversation): void {
      const indexOfConversation = conversations.map((x) => x.id).indexOf(conversation.id);
      let newConversations = [...conversations];
      newConversations.splice(indexOfConversation, 1);
      setUniqConversations(newConversations);
    }
    async function handlePaymentReceivedCount(conversationIds: number[]): Promise<void> {
      const response = await messagingApi.markConversationUnRead(conversationIds);
      if (response) {
        await fetchConversationsApi(conversationTypes, conversationFilters, false, false);
        fetchUnreadConversationsCount();
      }
    }

    function onMessageReceived(notification: Notification): void { 
      const { conversation }: MessageReceived = JSON.parse(notification.EventData);
      //If messenger opened from customer details
      if (defaultSearch && defaultSearch.includes('CrmCustomerId')) {
        const customerId = defaultSearch.substring(defaultSearch.indexOf(':') + 1, defaultSearch.length);
        if (conversation.customer.crmCustomerId.toString() != customerId) {
          return;
        }
      }
     
      //In archive tab and receive notification of conversation that is not archive then don't add the conversation message in the list.
      if( conversationTypes === ConversationType.Archive.Value && !conversation.isArchive )
        return;
       
      //delete the archived conversation from all and unread tab
      if ( !defaultSearch.includes('CrmCustomerId') 
        && conversationTypes !== ConversationType.Archive.Value
        && conversation.isArchive ) {
        deleteConversation(conversation);
      } 
      else if (conversation.latestMessage?.toLowerCase().trim() === 'stop') {
        deleteConversation(conversation);
      } else if (conversation.id === selectedConversation?.id) {
        setSelectedConversation(conversation);
      } else {
          
        //In case of search, don't add the receive message conversation
        if(searchText && searchText.length > 0) return;

        if (conversationFilters.includes('My Conversations')) {
          if (conversation.assignedUserId?.toLowerCase() === loggedInUserId.toLowerCase()) {
            updateConversationList(conversation);
          }
        } else {
          updateConversationList(conversation);
        }
      }
    }

    function onMessageSent(notification: Notification): void {
       //Defect 115740: Search Filter is not filtering Newly Receive message.
      if(searchText && searchText.length > 0) return;
      const { conversation }: MessageReceived = JSON.parse(notification.EventData);
      if (conversation.id === selectedConversation?.id) {
        setSelectedConversation(conversation);
      }
      else
      {
        updateConversationList(conversation);
      } 
    }

    function onConversationRead(notification: Notification): void {
      const conversation: Conversation = JSON.parse(notification.EventData);
      updateConversationList(conversation);
    }
    async function onConversationUnread(notification: Notification) {
      await fetchConversationsApi(conversationTypes, conversationFilters);
    }
    function onConversationCreated(notification: Notification): void {
      const conversation: Conversation = JSON.parse(notification.EventData);
      updateConversationList(conversation);
    }

    function onPaymentReceived(notification: Notification): void {
      const conversationId: [] = JSON.parse(notification.EventData);
      handlePaymentReceivedCount(conversationId);
    }

    if (signalRConnection) {
      signalRConnection.on('MessageReceived', onMessageReceived);
      signalRConnection.on('MessageSent', onMessageSent);
      signalRConnection.on('ConversationRead', onConversationRead);
      signalRConnection.on('ConversationCreated', onConversationCreated);
      signalRConnection.on('PaymentReceived', onPaymentReceived);
      signalRConnection.on('ConversationUnread', onConversationUnread);
    }
    return (): void => {
      if (signalRConnection) {
        signalRConnection.off('MessageReceived', onMessageReceived);
        signalRConnection.off('MessageSent', onMessageSent);
        signalRConnection.off('ConversationRead', onConversationRead);
        signalRConnection.off('ConversationCreated', onConversationCreated);
        signalRConnection.off('PaymentReceived', onPaymentReceived);
        signalRConnection.off('ConversationUnread', onConversationUnread);
      }
    };
  }, [conversations, selectedConversation, setSelectedConversation, signalRConnection]);




  let rightPanel = (
    <Box height="100%" display="flex" alignItems="center" justifyContent="center">
      <Box>
        <Box mb={1} width="100%" textAlign="center">
          <Typography variant="h5">Select a conversation on the left or start a new conversation to begin</Typography>
        </Box>
        <Box width="100%" textAlign="center">
          <Button
            variant="contained"
            color="primary"
            id="newConversationBtn"
            size="small"
            onClick={handleStartConversation}>
            New conversation
          </Button>
        </Box>
      </Box>
    </Box>
  );
  if (showStartNewConversation === true) {
    rightPanel = (
      <NewConversationPanel
        tag={defaultSearch}
        onContinue={handleOnContinue}
        loading={createConversationLoading}
        onStart={createNewConversation}
        defaultSearch={phoneNumber}
        disableSearch={hideNavigations}
        onCancel={(): void => setStartNewConversation(false)}
      />
    );
  }

  else if (selectedConversationIds.length > 0) {
    rightPanel = (
      <Box className={classes.updateConversationPanel}>
        <AppBar position="relative" style={{ backgroundColor: 'white', color: 'black' }} elevation={0}>
          <Toolbar variant="dense" disableGutters>
            <Box display='flex' flexGrow={2} justifyContent="left" paddingLeft="15px">
              <Typography variant="h5">
                <IconButton id="goBack" onClick={() => {
                  if (reAssignPanel) {
                    setReAssignPanel(false);
                  } else {
                    setConversationList([]);
                  }

                  setAutoCompleteDepartmentValue({ label: "Select Department", id: 0, dealerLocation: "" });
                  setAutoCompleteUserValue({ label: "Select User", id: 0 });
                  setSelectedUser("0");
                  setSelectedDepartment(0);

                }}>
                  <KeyboardBackspaceIcon />

                </IconButton>
                Update Conversation{selectedConversationIds.length > 1 ? 's' : ''}</Typography>
            </Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <Box marginTop="20%" display="flex" justifyContent="center">
          <Typography style={{ fontWeight: 600 }} variant="h4">Update {selectedConversationIds.length} Conversation{selectedConversationIds.length > 1 ? 's' : ''}</Typography>
        </Box>

        {
          (reAssignPanel) &&
          <Box display="flex" marginTop="10%" ml="10%" mr="10%" justifyContent="center">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mb={1} width="100%" textAlign="center">
                  <Typography variant="h5"> To assign a conversation, select a department if you don't know which specific teammate is available, or select a department and then a user.</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  // variant="outlined"
                  id="combo-box-demo"
                  options={autoCompleteDepartmentEntries.sort((a, b) => -b.dealerLocation.localeCompare(a.dealerLocation))}
                  // sx={{ width: 300 }}
                  groupBy={(option) => option.dealerLocation}
                  getOptionLabel={(option) => option.label || ""}
                  defaultValue={{ label: "Select Department", id: 0, dealerLocation: "" }}
                  value={autoCompleteDepartmentValue}
                  onChange={(e: any, data: any) => {
                    if (data) {
                      setSelectedDepartment(data.id);
                      setAutoCompleteDepartmentValue(data);
                    } else {
                      setSelectedDepartment(0);
                      setAutoCompleteDepartmentValue({ label: "Select Department", id: 0, dealerLocation: "" });
                    }
                  }}
                  renderInput={(params) => <TextField value={selectedDepartment} variant="outlined" {...params} label="Select Department" />}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  // variant="outlined"
                  id="combo-box-demo"
                  options={autoCompleteEntries}
                  // sx={{ width: 300 }}
                  getOptionLabel={(option) => option.label || ""}
                  defaultValue={{ label: "Select User", id: 0 }}
                  value={autoCompleteUserValue}
                  onChange={(e: any, data: any) => {
                    if (data) {
                      setSelectedUser(data.id);
                      setAutoCompleteUserValue(data);
                    } else {
                      setSelectedUser("0");
                      setAutoCompleteUserValue({ label: "Select User", id: 0 });
                    };
                  }}
                  renderInput={(params) => <TextField value={selectedUser} variant="outlined" {...params} label="Select User" />}
                />
              </Grid>

              <Grid item xs={12} >
                <Grid container justify="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ backgroundColor: '#F0B41B' }}
                      size="small"
                      onClick={(e) => { unAssignConversation() }}
                    >Un Assign</Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={(e) => { assignConversation() }}
                    >Assign</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        }
        {
          !reAssignPanel &&
          <Box display="flex" marginTop="10%" justifyContent="center">
            <Button
              className={classes.markBtn}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleMarkConvUnRead}
              startIcon={<MarkAsUnread style={{ width: 40, height: 40 }} />}
            >
              Mark Unread
            </Button>
            <Button
              className={classes.markBtn}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => { setReAssignPanel(true); }}
              startIcon={<PersonAddAltIcon style={{ width: 40, height: 40 }} />}
            >
              Assign
            </Button>
          </Box>
        }

        {/* <Box marginTop="10%" display="flex" justifyContent="center">
          <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
            Clear Selection
          </Button>
        </Box> */}
      </Box>
    );
  }
  else if (selectedConversation) {
    rightPanel = (
      <ConversationPanel
        ref={childRef}
        latestMessageSid={selectedMessageSid}
        key={selectedConversation.id}
        conversation={selectedConversation}
        openCustomerEditDrawer={(selectedCustomerId: number) => {
          dispatch(fetchSelectedCustomer(selectedCustomerId));
        }}
        onLatestMessageReceived={handleLatestMessageReceived}
        markConversationRead={handleMarkConversationRead}
        markConversationUnRead={handleMarkSingleConversationUnRead}
        assignConversation={handleAssignSingleConversation}
        markConversationArchive={handleMarkSingleConversationArchive}
        onMessageSentUpdateConversations={onMessageSentUpdateConversations}
        onMessageRecievedUpdateConversations={onMessageRecievedUpdateConversations}
      />
    );
  }
  const leftPanel = (
    <ConversationListPanel
      loading={loading}
      messageSid={selectedMessageSid}
      selectedConversation={selectedConversation}
      fetchNextPage={handleFetchConversationNextPage}
      fetchSortedConversation={handleSortedConversations}
      conversations={conversations}
      onSearch={handleSearchConversation}
      isSearchChecked={handleSearchChecked}
      onConversationSelect={handleOnConversationSelected}
      onStartConversation={handleStartConversation}
      //onStartConversation={defaultCategory === '' ? handleDefaultCategory : handleStartConversation}
      handleConversationCheckbox={handleConversationCheckbox}
      handleConvTypeChange={handleConvTypeChange}
      handleConvFiltersChange={handleConvFiltersChange}
      selectedConversationIds={selectedConversationIds}
      defaultSearch={defaultSearch}
    />
  );

  const containerClassName = clsx(classes.container, customerId && classes.containerShift);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const upLg = useMediaQuery(theme.breakpoints.up('lg'));
  const mdXl = useMediaQuery(theme.breakpoints.up('xl'));
  let containerMaxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false = 'lg';

  if (upLg) {
    containerMaxWidth = 'lg';
  }

  if (mdXl) {
    containerMaxWidth = 'lg';
  }

  if (mdDown) {
    containerMaxWidth = false;
  }
  if (smDown) {
    containerMaxWidth = false;
  }
  return (
    <>
      <Container className={containerClassName} maxWidth={containerMaxWidth}>

        {defaultSearch == '' && !hideOtherNavigation && (<PageHeader title="Messenger" />)}

        <Box my={2} component="section">
          <Paper className={classes.root}>
            <SplitPanel left={leftPanel} right={rightPanel} />
          </Paper>
        </Box>
        {defaultSearch == '' && (
          <CustomerRootPageDrawer />
        )}
      </Container>
      {/* <CustomerEditDrawer customerId={customerId} onClose={handleCustomerUpdated} /> */}
    </>
  );

  function ResetAutoComplete() {
    setAutoCompleteDepartmentValue({ label: "Select Department", id: 0, dealerLocation: "" });
    setAutoCompleteUserValue({ label: "Select User", id: 0 });
    setSelectedUser("0");
    setSelectedDepartment(0);
  }
}

export default Messenger;
